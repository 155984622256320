// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000;
  color:#d0d0d0;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.loading {
  width:100vw;
height:100vh;
display:flex;
justify-content:center;
align-items:center;
}


.global-message-container {
  width:100vw;
  height:100vh;
  display:flex;
  justify-content:center;
  align-items:center;
  font-size: 18px;
}


.global-message {
  text-align: center;
  font-size: 18px;
  padding: 10px;
}


.green {
  color: rgb(100, 250, 100);
}

.orange {
  color: orange;
}

.red {
  color: rgb(255, 99, 99);
}

.edit {
  display: inline-block;
  padding: 5px;
  cursor: pointer;
  height: 20px;
  width: 20px;
  opacity: 0.5;
}

.edit:hover {
  opacity: 1;
}

.edit img {
  width: 100% !important;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;;EAElC,qBAAqB;EACrB,mCAAmC;EACnC,kCAAkC;EAClC,yBAAyB;EACzB,aAAa;;AAEf;;AAEA;EACE;aACW;AACb;;AAEA;EACE,WAAW;AACb,YAAY;AACZ,YAAY;AACZ,sBAAsB;AACtB,kBAAkB;AAClB;;;AAGA;EACE,WAAW;EACX,YAAY;EACZ,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;EAClB,eAAe;AACjB;;;AAGA;EACE,kBAAkB;EAClB,eAAe;EACf,aAAa;AACf;;;AAGA;EACE,yBAAyB;AAC3B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,qBAAqB;EACrB,YAAY;EACZ,eAAe;EACf,YAAY;EACZ,WAAW;EACX,YAAY;AACd;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n\n  font-family: 'Roboto';\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  background-color: #000000;\n  color:#d0d0d0;\n\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n.loading {\n  width:100vw;\nheight:100vh;\ndisplay:flex;\njustify-content:center;\nalign-items:center;\n}\n\n\n.global-message-container {\n  width:100vw;\n  height:100vh;\n  display:flex;\n  justify-content:center;\n  align-items:center;\n  font-size: 18px;\n}\n\n\n.global-message {\n  text-align: center;\n  font-size: 18px;\n  padding: 10px;\n}\n\n\n.green {\n  color: rgb(100, 250, 100);\n}\n\n.orange {\n  color: orange;\n}\n\n.red {\n  color: rgb(255, 99, 99);\n}\n\n.edit {\n  display: inline-block;\n  padding: 5px;\n  cursor: pointer;\n  height: 20px;\n  width: 20px;\n  opacity: 0.5;\n}\n\n.edit:hover {\n  opacity: 1;\n}\n\n.edit img {\n  width: 100% !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
