import React, { Component } from 'react';
import Utils from '../../Utils'
import { Chart } from "react-google-charts";


const optionsServerChart = {
    width: 450,
    height: 120,
    redFrom: 90,
    redTo: 100,
    yellowFrom: 85,
    yellowTo: 90,
    minorTicks: 5,
  };


class ServerStats extends Component {
    constructor(props) {
        super(props);

        this.state = {
            details: props.details
        }
    }

    getServerData() {
        if (this.props.serverStats !== undefined) {
            return [
            ["Label", "Value"],
            ["Memory", (100.0*(this.props.serverStats[0].memoryUsageMb)/(this.props.serverStats[0].memoryFreeMb + this.props.serverStats[0].memoryUsageMb))],
            ["CPU", (Math.min(this.props.serverStats[0].cpuLoad*50.0, 80))],
            ["Threads", this.props.serverStats[0].threads],
            ];
        } else {
            return [
                ["Label", "Value"],
                ["Memory", 0],
                ["CPU", 0],
                ["Threads", 0],

                ];
        }
      }

    componentDidMount() {

    }


    componentWillUnmount() {
        
    }


    render() {


        return (<div className='serverStats'>
                <div className='fund-panel'>
                                    <div className='fund-panel-title '> Servers</div>

                                    <div className='chart_div_servers home-grid-panel-gauges-list' >
                                        <Chart
                                            chartType="Gauge"
                                            width="300px"
                                            height="120px"
                                            data={this.getServerData()}
                                            options={optionsServerChart}
                                        />
                                        

                                        </div>
                            </div>

                      </div>)
    }
}

export default ServerStats;