// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.countdown-container {
    display: block;
    width: 100%;
}

.trading-day-container {
    display: flex;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.trading-day-start {
    flex-grow: 1;
    font-size: 13px;
    margin-top: 3px;
    text-align: center;
}

.trading-day-progress {
    flex-grow: 10;

    background-color: #ddd;
    position: relative;

    border-radius: 5px;
}

.trading-day-end {
    flex-grow: 1;
    text-align: right;
    font-size: 13px;
    margin-top: 3px;
    text-align: center;

}

.trading-day-progress-bar {
    width: 50%;
    height: 20px;
    z-index: 999;

   background: repeating-linear-gradient(
        135deg,
        #181b2e,
        #181b2e 5px,
        #465298 5px,
        #465298 10px
        );

        border-radius: 5px;

}

.trading-day-remaining {
    position:absolute;
    color: #fff;
    top:0px;
    left:0px;
    text-align: center;
    width: 100%;
    text-shadow: 1px 1px #000;
    text-shadow:1px 1px 10px #000, 1px 1px 5px #000;

}`, "",{"version":3,"sources":["webpack://./src/Fund/Home/Countdown.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,WAAW;AACf;;AAEA;IACI,aAAa;IACb,WAAW;IACX,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,eAAe;IACf,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,aAAa;;IAEb,sBAAsB;IACtB,kBAAkB;;IAElB,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,iBAAiB;IACjB,eAAe;IACf,eAAe;IACf,kBAAkB;;AAEtB;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,YAAY;;GAEb;;;;;;SAMM;;QAED,kBAAkB;;AAE1B;;AAEA;IACI,iBAAiB;IACjB,WAAW;IACX,OAAO;IACP,QAAQ;IACR,kBAAkB;IAClB,WAAW;IACX,yBAAyB;IACzB,+CAA+C;;AAEnD","sourcesContent":[".countdown-container {\n    display: block;\n    width: 100%;\n}\n\n.trading-day-container {\n    display: flex;\n    width: 100%;\n    margin-top: 10px;\n    margin-bottom: 10px;\n}\n\n.trading-day-start {\n    flex-grow: 1;\n    font-size: 13px;\n    margin-top: 3px;\n    text-align: center;\n}\n\n.trading-day-progress {\n    flex-grow: 10;\n\n    background-color: #ddd;\n    position: relative;\n\n    border-radius: 5px;\n}\n\n.trading-day-end {\n    flex-grow: 1;\n    text-align: right;\n    font-size: 13px;\n    margin-top: 3px;\n    text-align: center;\n\n}\n\n.trading-day-progress-bar {\n    width: 50%;\n    height: 20px;\n    z-index: 999;\n\n   background: repeating-linear-gradient(\n        135deg,\n        #181b2e,\n        #181b2e 5px,\n        #465298 5px,\n        #465298 10px\n        );\n\n        border-radius: 5px;\n\n}\n\n.trading-day-remaining {\n    position:absolute;\n    color: #fff;\n    top:0px;\n    left:0px;\n    text-align: center;\n    width: 100%;\n    text-shadow: 1px 1px #000;\n    text-shadow:1px 1px 10px #000, 1px 1px 5px #000;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
