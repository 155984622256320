import React, { Component } from 'react';
import './Login.css'
import '../Utils'
import ReactLoading from "react-loading";
import Utils from '../Utils';

class Login extends Component {

    constructor(props) {
        super(props);

        this.state = {
            usernameField: "",
            passwordField: "",
            errorMessage: "",
            submitted : false
        }
    }

    componentDidMount() {
    }
    
    
    componentWillUnmount() {
        
    }

    submit() {
        this.setState( {
            submitted: true,
            errorMessage: ""
        })
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ username: this.state.usernameField, password: this.state.passwordField })
        };
        fetch('https://api-dashboard.goodmarkets.com/Auth/Login', requestOptions)
            .then(response => {
                if (response.status === 401) {
                    console.log("password not valid")
                    this.setState({
                        submitted:false,
                        errorMessage: "Username/Password not valid"
                    })
                } else {
                    return response.json()
                }
            })
            .then(data => 
                {
                    if (data.jwt !== undefined && data.expiration !== undefined) {
                        localStorage.setItem("token", JSON.stringify(data))
                        // callback for the login
                        this.props.callback.setLoggedIn()

                        Utils.sendEvent("LOGIN")
                    } else {
                        this.setState({
                            submitted:false,
                            errorMessage: "Contact support"
                        })
                    }
                }
            ).catch(error => {
                console.log(error)
            });
    }

    render() {

        var error;

        if (this.state.errorMessage !== "") {
            error = <div className='login-error'>{this.state.errorMessage}</div>
        }

        var submitButton = <div className='login-submit'
                                onClick={() => {
                                    this.submit()
                                }} >Login</div>

        if (this.state.submitted) {
            submitButton = <div className='password-loading'><ReactLoading
                type={"bars"}
                color={"#03fc4e"}
                height={"50px"}
                width={"100px"}
              /></div>
        }

        return (<div className='login-container'>
            <div className='login-logo'><img src='logo-white.png'  /></div>
            {error}
            <div className='login-form'>
                <div className="username-box">
                    <label for="username">Email</label>
                    <input type="email" id="username" name="username"
                    onChange={(e) => {
                        this.setState({
                            usernameField: e.target.value
                        })
                    }} 

                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            this.submit()
                        }
                    }}
                    />
                </div>
                <div className="password-box">
                    <label for="password">Password</label>
                    <input type="password" id="password" name="password"
                    onChange={(e) => {
                        this.setState({
                            passwordField: e.target.value
                        })
                    }} 

                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            this.submit()
                        }
                    }}
                    />
                </div>
                {submitButton}
            </div>

        </div>)

    }
}

export default Login;