import React, { Component } from 'react';
import Utils from '../Utils'
import ReactLoading from "react-loading";
import Fund from '../Fund/Fund';
import Header from './Header';
import './Dashboard.css'
import './Header.css'

class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fundDetails: undefined,
            userDetails: undefined,
            loading: false,
            dashboard: undefined
        }
    }



    componentDidMount() {
        this.setState({
            loading: true
        })
        var token = Utils.getToken()
        if (token === null) {
            this.props.callback.setLoggedOut()
            return;
        }

        const requestOptions = {
            method: 'GET',
            headers: { 
            "Authorization": "Bearer " + token
             }
        };
        fetch('https://api-dashboard.goodmarkets.com/Auth/Details', requestOptions)
            .then(response => {
                if (response.status === 401) {
                    this.props.callback.setLoggedOut()
                    return;
                } else {
                    return response.json()
                }
            })
            .then(data => 
                {
                    this.setState({
                        loading: false,
                    })
                    this.state.userDetails = data
                    if (data.isAdmin === true) {
                        Utils.isAdmin = true
                    }
                    this.updateFund()
                }
            ).catch(error => {
                console.log(error)
            });

            window.scrollTo(0, 0)

    }

    
    updateFund() {
        if (this.state.fundDetails === undefined && this.state.userDetails.accounts.length > 0) {
            if (this.state.userDetails.accounts.length === 1) {
                if (this.state.userDetails.accounts[0].fund !== undefined) {
                    this.setState({
                        fundDetails: this.state.userDetails.accounts[0].fund
                    })
                }
            }
        }
        if (this.state.fundDetails !== undefined) {
            // load dashboard for fund

        }

    }

    
    componentWillUnmount() {
        
    }

    // used in callback
    logout() {
        this.props.callback.setLoggedOut()
    }

    selectFund(fund) {
        this.setState({
            fundDetails: fund
        })

    }

    clearFund() {
        this.setState({
            fundDetails: undefined
        })
    }

    render() {

        if (this.state.loading) {
            return (<div className='loading'><ReactLoading
                type={"bars"}
                color={"#03fc4e"}
                height={100}
                width={100}
              /></div>)
        }

        if (this.state.userDetails === undefined) {
            return <div className='global-message-container'>
                        <div className='global-message'>
                        Error loading user details
                        </div>
                </div>
        }

        if (this.state.userDetails.accounts.length === 0) {
            return <div className='global-message-container'>
                        <div className='global-message'>
                        No accounts associated with user
                        </div>
                </div>
        }

        if (this.state.userDetails.accounts.length === 1
            && this.state.userDetails.accounts[0].fund === undefined) {
            return <div className='global-message-container'>
                        <div className='global-message'>
                        No fund associated with user
                        </div>
                </div>
        }

        if (this.state.userDetails.accounts.length > 1
            && this.state.fundDetails === undefined
        ) {
            var funds = []
            this.state.userDetails.accounts.forEach(element => {
                
                funds.push(<div className='fund-select-fund' onClick={()=>{this.selectFund(element.fund)}}>
                    {element.fund.name}
                </div>)

            });

            // show account selector
            return (<div className='fund-select'>
                    <div className='fund-select-container'>
                        <div className='fund-select-title'>
                            
                            <div className='fund-select-title-text'>Select a Fund</div>
                            <div className='header-buton-dark'
                                onClick={()=> {this.logout()}}
                                >Logout</div>
                                </div>
                        <div className='fund-select-list'>
                            {funds}
                        </div>
                    </div>
            </div>)
        }

        if (this.state.fundDetails === undefined) {
            return (<div className='loading'><ReactLoading
                type={"bars"}
                color={"#03fc4e"}
                height={100}
                width={100}
              /></div>)
        }
        

        return <div><div><Header callback={this} userDetails={this.state.userDetails} /></div>
            <Fund callback={this} details={this.state.fundDetails} userDetails={this.state.userDetails}  />
        </div>
    }
}

export default Dashboard;