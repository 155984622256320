import React, { Component } from 'react';
import { Chart } from "react-google-charts";
import ReactLoading from "react-loading";
import Utils from '../../Utils';
import NAVHistory from './NAVHistory';


class AnalyticsOverview extends Component {


    constructor(props) {
        super(props);
        this.state = {
            details: props.details,
            dailyNav : undefined
        };
    }
    
    componentDidMount() {


        var token = Utils.getToken()
        if (token === null) {
            this.props.callback.logout()
            return;
        }

        

        const requestOptions = {
            method: 'GET',
            headers: { 
            "Authorization": "Bearer " + token
             }
        };
        fetch('https://api-dashboard.goodmarkets.com/Analytics/GetNAV/'+this.state.details.id, requestOptions)
            .then(response => {
                if (response.status === 401) {
                    this.props.callback.logout()
                    return;
                } else {
                    return response.json()
                }
            })
            .then(data => 
                {
                    this.setState({
                        dailyNav: data,
                    })
                    
                }
            ).catch(error => {
                console.log(error)
               
            });
    }
    
    
    componentWillUnmount() {
        
    }
    
    numberWithCommas(x, decimals = 2) {
        return Number.parseFloat(x).toFixed(decimals).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    getMtdRealised() {

        let currentMonth = new Date().getUTCMonth() + 1;

        var mtdRealised = 0

        mtdRealised += this.props.summary.stats.todaysRealised
        for (var i = this.state.dailyNav.length - 1; i >= 0; i--) {
            let dateObject = new Date(this.state.dailyNav[i].closeTime);
            let dateMonth = dateObject.getUTCMonth() + 1;

            if (dateMonth === currentMonth) {
                mtdRealised += this.state.dailyNav[i].realisedPnl
            } else {
                break;
            }
        }
        return mtdRealised
    }

    getMtdVolume() {

        let currentMonth = new Date().getUTCMonth() + 1;

        var mtd = 0

        mtd += this.props.summary.stats.todaysVolume
        for (var i = this.state.dailyNav.length - 1; i >= 0; i--) {
            let dateObject = new Date(this.state.dailyNav[i].closeTime);
            let dateMonth = dateObject.getUTCMonth() + 1;

            if (dateMonth === currentMonth) {
                mtd += this.state.dailyNav[i].volume
            } else {
                break;
            }
        }
        return mtd
    }

    getMaxDrawdown() {
        let maxDrawdown = 0;
        let peakDiff = this.state.dailyNav[0].equity - this.state.dailyNav[0].netDeposits;
        let peak = this.state.dailyNav[0].equity
        for (var i = 0; i < this.state.dailyNav.length; i++) {
            var nav = this.state.dailyNav[i]
            if (nav.equity - nav.netDeposits > peakDiff) {
                peakDiff = nav.equity - nav.netDeposits;
                peak = nav.equity
            }

            let drawdown = (peakDiff  - (nav.equity - nav.netDeposits)) / (peak);
            if (drawdown > maxDrawdown) {
                maxDrawdown = drawdown;
            }
        }
        return maxDrawdown*100
    }

    getMtdRoe() {
        let currentMonth = new Date().getUTCMonth() + 1;

        var lastMonthEquity = 0

        var equityAdjustment = 0

        for (var i = this.state.dailyNav.length - 1; i >= 0; i--) {
            let dateObject = new Date(this.state.dailyNav[i].closeTime);
            let dateMonth = dateObject.getUTCMonth() + 1;

            if (dateMonth === currentMonth) {
                equityAdjustment += this.state.dailyNav[i].equityAdjustment
            } else {
                lastMonthEquity = this.state.dailyNav[i].equity
                break;
            }
        }
        if (lastMonthEquity === 0) {
            if (this.state.dailyNav.length > 0) {
                lastMonthEquity = this.state.dailyNav[0].equity 
                return ((this.props.summary.stats.currentNAV-equityAdjustment)/lastMonthEquity)*100
            } else {
                return 0
            }
        }
        return ((this.props.summary.stats.currentNAV-equityAdjustment -lastMonthEquity)/lastMonthEquity)*100
    }

    getTotalDeposits() {
        let amount = 0
        for (var i = 0; i < this.state.dailyNav.length; i++) {
            var nav = this.state.dailyNav[i]
            if (nav.equityAdjustment > 0) {
                amount += nav.equityAdjustment
            }
        }

        return amount
    }

    getTotalWithdrawals() {
        let amount = 0
        for (var i = 0; i < this.state.dailyNav.length; i++) {
            var nav = this.state.dailyNav[i]
            if (nav.equityAdjustment < 0) {
                amount += nav.equityAdjustment
            }
        }

        return Math.abs(amount)
    }

    getNetDeposits() {
        let amount = 0
        for (var i = 0; i < this.state.dailyNav.length; i++) {
            var nav = this.state.dailyNav[i]
            amount += nav.equityAdjustment
        }

        return amount
    }


    getTotalEquityReturn() {
        let amount = 0
        this.state.dailyNav.forEach(element => {
            amount += element.equityReturn
        });
        return amount*100
    }
    

    render() {
        


        if (this.state.dailyNav === undefined) {
            return <div className='loading'><ReactLoading
                type={"bars"}
                color={"#03fc4e"}
                height={100}
                width={100}
            /></div>
        }
   
                
        var mtdRealised = this.getMtdRealised();
        return (
            <div>
            
                <div className='fund-home'>
                    <div className='fund-home-summary'>
                        <div className='fund-panel'>
                            <div className='fund-panel-title'>
                                Fund Analytics
                            </div>
                            <div className='fund-panel-body'>
                            

                                <div className='fund-panel-row'>
                                    <div className='fund-panel-row-key'>Current NAV</div>
                                    <div className={'fund-panel-row-value '}>${this.numberWithCommas(this.props.summary.stats.currentNAV, 0)}</div>
                                </div>
                                <div className='fund-panel-row'>
                                    <div className='fund-panel-row-key'>Current ROE</div>
                                    <div className={'fund-panel-row-value ' + (this.getTotalEquityReturn() >= 0 ? 'green' : 'red')}>{this.numberWithCommas(this.getTotalEquityReturn(), 2)}%</div>
                                </div>
                                <div className='panel-divider'></div>
                                <div className='fund-panel-row'>
                                    <div className='fund-panel-row-key'>MTD Realised PNL</div>
                                    <div className={'fund-panel-row-value ' + (mtdRealised < 0 ? 'red' : 'green')}>{mtdRealised < 0 ? '-' : '+'}${Utils.numberWithCommas(Math.abs(mtdRealised), 0)}</div>
                                </div>

                                <div className='fund-panel-row'>
                                    <div className='fund-panel-row-key'>MTD ROE</div>
                                    <div className={'fund-panel-row-value ' + (this.getMtdRoe() >= 0 ? 'green' : 'red') }>{this.numberWithCommas(this.getMtdRoe(), 2)}%</div>
                                </div>

                                <div className='fund-panel-row'>
                                    <div className='fund-panel-row-key'>MTD Volume</div>
                                    <div className={'fund-panel-row-value '}>${this.numberWithCommas(this.getMtdVolume(), 0)}</div>
                                </div>
                                <div className='panel-divider'></div>
                                <div className='fund-panel-row'>
                                    <div className='fund-panel-row-key'>Max Drawdown</div>
                                    <div className={'fund-panel-row-value red'}>{this.numberWithCommas(this.getMaxDrawdown(), 3)}%</div>
                                </div>

                                <div className='fund-panel-row'>
                                    <div className='fund-panel-row-key'>Total Deposits</div>
                                    <div className={'fund-panel-row-value green'}>${this.numberWithCommas(this.getTotalDeposits(), 0)}</div>
                                </div>

                                <div className='fund-panel-row'>
                                    <div className='fund-panel-row-key'>Total Withdrawals</div>
                                    <div className={'fund-panel-row-value red'}>${this.numberWithCommas(this.getTotalWithdrawals(), 0)}</div>
                                </div>

                                <div className='fund-panel-row'>
                                    <div className='fund-panel-row-key'>Net Deposits</div>
                                    <div className={'fund-panel-row-value ' + (this.getNetDeposits() >= 0 ? 'green' : 'red')}>${this.numberWithCommas(this.getNetDeposits(), 0)}</div>
                                </div>

                                
        
                            </div>
                        </div>
                    </div>
                    <div className='fund-home-details'>
                        <NAVHistory dailyReports={this.state.dailyNav} />
                    </div>
                </div>
            </div>
            );
    }


}

export default AnalyticsOverview;