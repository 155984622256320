import React, { Component } from 'react';
import { Chart } from "react-google-charts";
import ReactLoading from "react-loading";
import Utils from '../../Utils';
import NAVHistory from './NAVHistory';
import AnalyticsOverview from './AnalyticsOverview';
import AnalyticsExposure from './AnalyticsExposure';
import AnalyticsCycles from './AnalyticsCycles';



class Analytics extends Component {
    
   
    constructor(props) {
        super(props);
        
        this.state = {
            selectedTab: "overview"
        }
    }

    logout() {
        this.props.callback.logout()
    }
    
    componentDidMount() {
  
    }
    
    
    componentWillUnmount() {
        
    }
    
    numberWithCommas(x, decimals = 2) {
        return Number.parseFloat(x).toFixed(decimals).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    selectTab(tab) {
        this.setState({
            selectedTab: tab
        })
    }
    

    render() {
        
            let view = undefined

            if (this.state.selectedTab === "overview") {
                view = <AnalyticsOverview details={this.props.details} callback={this} summary={this.props.summary} />
            } else if (this.state.selectedTab === "exposure") {
                view = <AnalyticsExposure details={this.props.details} callback={this} summary={this.props.summary} />
            } else if (this.state.selectedTab === "cycles") {
                view = <AnalyticsCycles details={this.props.details} callback={this} summary={this.props.summary} />
            }

            return (
                <div>

                    <div className='sub-tabs'>
                        <div className={'sub-tab' + (this.state.selectedTab === "overview" ? ' sub-tab-selected' : '')} onClick={()=>{this.selectTab("overview")}}>Overview</div>
                        <div className={'sub-tab' + (this.state.selectedTab === "exposure" ? ' sub-tab-selected' : '')} onClick={()=>{this.selectTab("exposure")}}>Exposure</div>
                        <div className={'sub-tab' + (this.state.selectedTab === "cycles" ? ' sub-tab-selected' : '')} onClick={()=>{this.selectTab("cycles")}}>Cycles</div>
                        <div className={'sub-tab' + (this.state.selectedTab === "recommendations" ? ' sub-tab-selected' : '')} onClick={()=>{this.selectTab("recommendations")}}>Recommendations</div>
                    </div>

                    {view}
                </div>

            )

        }
    }              
export default Analytics;