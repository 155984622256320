import React, { Component } from 'react';
import Utils from '../../Utils'
import './GroupsSummary.css'

class GroupsSummary extends Component {


    componentDidMount() {

    }


    componentWillUnmount() {
        
    }


    render() {

        var groups = this.props.groups

        var groupsSummary = []

        groups.forEach((group) => {

            var editButton

            if (Utils.isAdmin) {
                editButton = <div className='edit'><img src='edit.png' /></div>
            }

            groupsSummary.push(<div className='groupSummary'>
                <div className='groupSummary-left' onClick={()=>{Utils.scroll(group.id)}}>
                    <div className='groupSummary-symbol'><img src={group.name.split(" ")[0].toLowerCase().replace("/", "") + ".svg"} /> {group.name} {editButton}</div>
                </div>
                <div className='groupSummary-right'>
                    <div className={'groupSummary-realised '  + (group.realisedPnl < 0 ? 'red' : 'green')}>{Utils.numberWithCommas(group.realisedPnl, 2)}</div>
                    <div className={'groupSummary-unrealised ' + (group.unrealisedPnl < 0 ? 'red' : 'green')}>{Utils.numberWithCommas(group.unrealisedPnl, 2)}</div>
                    <div className={'groupSummary-net ' + ((group.realisedPnl + group.unrealisedPnl) < 0 ? 'red' : 'green')}>{Utils.numberWithCommas(group.realisedPnl + group.unrealisedPnl, 2)}</div>

                </div>
            </div>)
            }
        )


        return (<div className='groupsSummary-container'>
            {groupsSummary}
                      </div>)
    }
}

export default GroupsSummary;