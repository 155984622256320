// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.exposure {
    display: flex;
    text-align: left;
}

.exposure-cell {
    flex: 1 1;
    padding: 5px;
}

.exposure-title {
    font-weight: bold;
    font-size: 18px;
}

.exposure-currency {
    font-weight: bold;
}

.exposure-long {

}

.exposure-short {

    
}

.exposure-max {
    font-weight: bold;
}

.exposure-percent {

}

.exposure-suggest {
    font-weight: bold;
}

.exposure-adjustment {

}`, "",{"version":3,"sources":["webpack://./src/Fund/Analytics/AnalyticsExposure.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,SAAa;IACb,YAAY;AAChB;;AAEA;IACI,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;;AAEA;;AAEA;;;AAGA;;AAEA;IACI,iBAAiB;AACrB;;AAEA;;AAEA;;AAEA;IACI,iBAAiB;AACrB;;AAEA;;AAEA","sourcesContent":[".exposure {\n    display: flex;\n    text-align: left;\n}\n\n.exposure-cell {\n    flex: 1 1 0px;\n    padding: 5px;\n}\n\n.exposure-title {\n    font-weight: bold;\n    font-size: 18px;\n}\n\n.exposure-currency {\n    font-weight: bold;\n}\n\n.exposure-long {\n\n}\n\n.exposure-short {\n\n    \n}\n\n.exposure-max {\n    font-weight: bold;\n}\n\n.exposure-percent {\n\n}\n\n.exposure-suggest {\n    font-weight: bold;\n}\n\n.exposure-adjustment {\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
