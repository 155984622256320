import React, { Component } from 'react';
import Utils from '../../Utils'
import './PositionLadder.css'

class PositionLadder extends Component {
    constructor(props) {
        super(props);

        this.state = {
            details: props.details
        }
    }

    componentDidMount() {

    }


    componentWillUnmount() {
        
    }


    render() {

        var positionLadder = this.props.positionLadder
        var nop = this.props.nop

        let positions = []

        var concentrationLimits = {
            "GBP" : [56, 49, 0],
            "EUR" : [56, 49, 0],
            "JPY" : [56, 49, 0],
            "AUD" : [36, 29, 0],
            "NZD" : [36, 29, 0],
            "CAD" : [36, 29, 0],
            "NOK" : [36, 29, 0],
            "SEK" : [36, 29, 0],
            "CHF" : [36, 29, 0],
            "USD" : [100, 100, 0],
        }
        
        

        positionLadder.sort(
            function(a,b) { 
                if (a === undefined || b === undefined) { 
                    return 0 
                }
                return a.currency.localeCompare(b.currency)
            }
                ).forEach((pos) => {

                    
                    var position = pos

                    var editButton

                    if (Utils.isAdmin) {
                        editButton = <div className='edit'><img src='edit.png' /></div>
                    }
                    if (position.currency === "USD") {
                        editButton = undefined
                    }

                    var concentration = Math.abs(position.amountUsd)*100/nop.limit

                    var conColor = "green"
                    if (concentration > concentrationLimits[position.currency][0]) {
                        conColor = "red"
                    } else if (concentration > concentrationLimits[position.currency][1]) {
                        conColor = "orange"
                    } else if (concentration > concentrationLimits[position.currency][2]) {
                        conColor = "green"
                    }

                    positions.push(
                        <div className='position'>
                            <div className='position-currency'>
                            {position.currency} (<span className={conColor}>{Utils.numberWithCommas(concentration, 1)}%</span>) {editButton}
                            </div>

                            <div className='position-details'>
                                <div className='position-details-amount'>
                                    {Utils.numberWithCommas(position.amount, 0)}
                                </div>
                                <div className={'position-details-amountusd ' + (position.amountUsd < 0 ? 'red' : 'green')}>
                                    {Utils.numberWithCommas(position.amountUsd, 0)} USD
                                </div>
                            </div>
                        </div>
                        )
                    });

        return (<div className='positionLadder'>
            {positions}
                      </div>)
    }
}

export default PositionLadder;