import React, { Component } from 'react';
import Utils from '../Utils'
import './Header.css'

class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userDetails: undefined
        }
    }

    componentDidMount() {

    }


    componentWillUnmount() {
        
    }

    logout() {
        Utils.sendEvent("LOGOUT")
        this.props.callback.logout()
    }

    switch() {
        this.props.callback.clearFund()
    }

    render() {
        var switchButton;
        if (this.props.userDetails.accounts.length > 1) {
            switchButton = <div className='header-buton'
                    onClick={()=> {this.switch()}}
                    >Funds</div>
        }
        return (
        <div className='header-container'>
            <div className='header-left'>
                <div className='header-logo'><img src='logo-white.png'  /></div>
            </div>
            <div className='header-right'>
                {switchButton}
                <div className='header-buton'
                    onClick={()=> {this.logout()}}
                    >Logout</div>
            </div>
        </div>)
    }
}

export default Header;