import React, { Component } from 'react';
import Utils from '../../Utils'
import './LatestTrades.css'

class LatestTrades extends Component {
    constructor(props) {
        super(props);

        this.state = {
            details: props.details
        }
    }

    componentDidMount() {

    }


    componentWillUnmount() {
        
    }


    render() {

        var latestTrades = this.props.latestTrades


        var trades = []

        latestTrades.forEach((trade) => {
            trades.push(<div className='latestTrade'>
                <div className='latestTrade-symbol'><img src={trade.symbol.toLowerCase().replace("/", "") + ".svg"} /> {trade.symbol}</div>
                <div className={'latestTrade-side ' + (trade.side === 'SELL' ? 'red' : 'green')}>{trade.side}</div>
                <div className='latestTrade-quantity'>{Utils.numberWithCommas(trade.quantity, 0)}</div>
                <div className='latestTrade-price'>{trade.price}</div>
                <div className='latestTrade-notional'>${Utils.numberWithCommas(trade.notional, 2)}</div>
                <div className='latestTrade-fee'>Fee: <span className='red'>${trade.fee}</span></div>
                <div className='latestTrade-date'>{new Date(trade.date).toUTCString().split(",")[1]}</div>
                <div className='latestTrade-exchange'><img src={trade.exchange + '.png'} /> {Utils.lpName(trade.exchange)}</div>

            </div>)
            }
        )

        return (<div className='latestTrades'>
            {trades}
                      </div>)
    }
}

export default LatestTrades;