import React, { Component } from 'react';
import Utils from '../../Utils'
import './AdminAlerts.css'

class AdminAlerts extends Component {
    constructor(props) {
        super(props);

        this.state = {
            details: props.details
        }
    }

    componentDidMount() {

    }


    componentWillUnmount() {
        
    }


    render() {

        var alertsList = this.props.alerts

        var alerts = [] 

        alertsList.forEach(alert => {
            var colour = 'green'
            if (alert.priority === 0) {
                colour = 'red'
            } else if (alert.priority === 1) {
                colour = 'orange'
            } 
            alerts.push(
                <div className='alert'>
                    <div className={'alert-title ' + colour}>{alert.title}</div>
                    <div className='alert-description'>{alert.description}</div>
                </div>
            )
        });

        return (<div className='adminAlerts'>
                    {alerts}
                      </div>)
    }
}

export default AdminAlerts;