import React, { Component } from 'react';
import { Chart } from "react-google-charts";
import ReactLoading from "react-loading";
import Utils from '../../Utils';
import NAVHistory from './NAVHistory';
import AnalyticsOverview from './AnalyticsOverview';
import './AnalyticsExposure.css'


class AnalyticsExposure extends Component {
    
   
    constructor(props) {
        super(props);
        this.state = {
            details: props.details,
            exposure : undefined
        };
    }

    logout() {
        this.props.callback.logout()
    }
    
    componentDidMount() {
  


        var token = Utils.getToken()
        if (token === null) {
            this.props.callback.logout()
            return;
        }

        

        const requestOptions = {
            method: 'GET',
            headers: { 
            "Authorization": "Bearer " + token
             }
        };
        fetch('https://api-dashboard.goodmarkets.com/Analytics/GetExposure/'+this.state.details.id, requestOptions)
            .then(response => {
                if (response.status === 401) {
                    this.props.callback.logout()
                    return;
                } else {
                    return response.json()
                }
            })
            .then(data => 
                {
                    this.setState({
                        exposure: data,
                    })
                    
                }
            ).catch(error => {
                console.log(error)
               
            });
    }
    
    
    componentWillUnmount() {
        
    }
    
    numberWithCommas(x, decimals = 2) {
        return Number.parseFloat(x).toFixed(decimals).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }


    render() {
        
        if (this.state.exposure === undefined) {
            return <div className='loading'><ReactLoading
                type={"bars"}
                color={"#03fc4e"}
                height={100}
                width={100}
            /></div>
        }
   

        var exposures = []

        this.state.exposure.forEach(e => {
            
            exposures.push(
                <div className='exposure'>
                    <div className='exposure-cell exposure-currency'>{e.currency}</div>
                    <div className='exposure-cell exposure-long green'>{this.numberWithCommas(e.longExposure, 0)}</div>
                    <div className='exposure-cell exposure-short red'>{this.numberWithCommas(e.shortExposure, 0)}</div>
                    <div className='exposure-cell exposure-max'>{this.numberWithCommas(e.maxExposure, 0)}</div>
                    <div className='exposure-cell exposure-percent'>{this.numberWithCommas(e.percentOfNop*100, 1)}%</div>
                    <div className='exposure-cell exposure-suggest'>{this.numberWithCommas(e.suggestedExposure, 0)}</div>
                    <div className={'exposure-cell exposure-adjustment ' + (e.suggestedAdjustment < 0 ? 'red' : 'green')}>{this.numberWithCommas(e.suggestedAdjustment, 0)}</div>

                </div>
            )
        });

        var titles = <div className='exposure'>
                        <div className='exposure-cell exposure-currency exposure-title'>Currency</div>
                        <div className='exposure-cell exposure-long exposure-title'>Long</div>
                        <div className='exposure-cell exposure-short exposure-title'>Short</div>
                        <div className='exposure-cell exposure-max exposure-title'>Max Exposure</div>
                        <div className='exposure-cell exposure-percent exposure-title'>NOP</div>
                        <div className='exposure-cell exposure-suggest exposure-title'>Suggested</div>
                        <div className='exposure-cell exposure-adjustment exposure-title'>Adjustment</div>
                    </div>
            return (
                <div>
            
                <div className='fund-home'>
                    <div className='fund-home-details'>
                        <div className='fund-panel'>
                            <div className='fund-panel-title'>
                                Exposure
                            </div>
                            <div className='fund-panel-body'>
                                {titles}

                                {exposures}
                                
        
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            )

        }
    }              
export default AnalyticsExposure;