import React, { Component } from 'react';
import Utils from '../../Utils'
import './Home.css'
import LatestTrades from './LatestTrades';
import ReactLoading from "react-loading";
import PositionLadder from './PositionLadder';
import PNL from './PNL';
import GroupsSummary from './GroupsSummary';
import GroupDetails from './GroupDetails';
import Countdown from './Countdown';
import ServerStats from './ServerStats';
import AdminAlerts from './AdminAlerts';
import UnsweptProfits from './UnsweptProfits';

class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            traderFunctionLoading: false,
            loading: false,
            details: props.details,
            showTraderFunctions: false
        }
    }

    componentDidMount() {

    }


    componentWillUnmount() {
        
    }

    showTraderFunctions() {
        this.setState({
            showTraderFunctions: true
        })
    }

    startTrader() {
        var token = Utils.getToken()
        if (token === null) {
            this.props.callback.logout()
            return;
        }

        this.setState({
            traderFunctionLoading: true,
        })

        const requestOptions = {
            method: 'GET',
            headers: { 
            "Authorization": "Bearer " + token
             }
        };
        fetch('https://api-dashboard.goodmarkets.com/Trader/StartGoodMarketsTrader', requestOptions)
            .then(response => {
                if (response.status === 401) {
                    this.props.callback.logout()
                    return;
                } else {
                    return response.json()
                }
            })
            .then(data => 
                {
                    this.setState({
                        traderFunctionLoading: false,
                    })
                    
                }
            ).catch(error => {
                this.setState({
                    traderFunctionLoading: false,
                })
                console.log(error)
            });
    }

    subscribeMarkets() {
        var token = Utils.getToken()
        if (token === null) {
            this.props.callback.logout()
            return;
        }

        this.setState({
            traderFunctionLoading: true,
        })

        const requestOptions = {
            method: 'GET',
            headers: { 
            "Authorization": "Bearer " + token
             }
        };
        fetch('https://api-dashboard.goodmarkets.com/Trader/SubscribeAllMarkets', requestOptions)
            .then(response => {
                if (response.status === 401) {
                    this.props.callback.logout()
                    return;
                } else {
                    return response.json()
                }
            })
            .then(data => 
                {
                    this.setState({
                        traderFunctionLoading: false,
                    })
                    
                }
            ).catch(error => {
                console.log(error)
                this.setState({
                    traderFunctionLoading: false,
                })
            });
    }

    reloadBots() {
        var token = Utils.getToken()
        if (token === null) {
            this.props.callback.logout()
            return;
        }

        this.setState({
            traderFunctionLoading: true,
        })

        const requestOptions = {
            method: 'GET',
            headers: { 
            "Authorization": "Bearer " + token
             }
        };
        fetch('https://api-dashboard.goodmarkets.com/Trader/ReloadBots', requestOptions)
            .then(response => {
                if (response.status === 401) {
                    this.props.callback.logout()
                    return;
                } else {
                    return response.json()
                }
            })
            .then(data => 
                {
                    this.setState({
                        traderFunctionLoading: false,
                    })
                    
                }
            ).catch(error => {
                console.log(error)
                this.setState({
                    traderFunctionLoading: false,
                })
            });
    }

    pauseTrading() {
        var token = Utils.getToken()
        if (token === null) {
            this.props.callback.logout()
            return;
        }

        this.setState({
            traderFunctionLoading: true,
        })

        const requestOptions = {
            method: 'GET',
            headers: { 
            "Authorization": "Bearer " + token
             }
        };
        fetch('https://api-dashboard.goodmarkets.com/Trader/PauseTrading', requestOptions)
            .then(response => {
                if (response.status === 401) {
                    this.props.callback.logout()
                    return;
                } else {
                    return response.json()
                }
            })
            .then(data => 
                {
                    this.setState({
                        traderFunctionLoading: false,
                    })
                    
                }
            ).catch(error => {
                console.log(error)
                this.setState({
                    traderFunctionLoading: false,
                })
            });
    }

    resumeTrading() {
        var token = Utils.getToken()
        if (token === null) {
            this.props.callback.logout()
            return;
        }

        this.setState({
            traderFunctionLoading: true,
        })

        const requestOptions = {
            method: 'GET',
            headers: { 
            "Authorization": "Bearer " + token
             }
        };
        fetch('https://api-dashboard.goodmarkets.com/Trader/ResumeTrading', requestOptions)
            .then(response => {
                if (response.status === 401) {
                    this.props.callback.logout()
                    return;
                } else {
                    return response.json()
                }
            })
            .then(data => 
                {
                    this.setState({
                        traderFunctionLoading: false,
                    })
                    
                }
            ).catch(error => {
                console.log(error)
                this.setState({
                    traderFunctionLoading: false,
                })
            });
    }

    logoutTrader() {
        var token = Utils.getToken()
        if (token === null) {
            this.props.callback.logout()
            return;
        }

        this.setState({
            traderFunctionLoading: true,
        })

        const requestOptions = {
            method: 'GET',
            headers: { 
            "Authorization": "Bearer " + token
             }
        };
        fetch('https://api-dashboard.goodmarkets.com/Trader/Logout', requestOptions)
            .then(response => {
                if (response.status === 401) {
                    this.props.callback.logout()
                    return;
                } else {
                    return response.json()
                }
            })
            .then(data => 
                {
                    this.setState({
                        traderFunctionLoading: false,
                    })
                    
                }
            ).catch(error => {
                console.log(error)
                this.setState({
                    traderFunctionLoading: false,
                })
            });
    }

    killTrader() {
        var token = Utils.getToken()
        if (token === null) {
            this.props.callback.logout()
            return;
        }

        this.setState({
            traderFunctionLoading: true,
        })

        const requestOptions = {
            method: 'GET',
            headers: { 
            "Authorization": "Bearer " + token
             }
        };
        fetch('https://api-dashboard.goodmarkets.com/Trader/KillTrader', requestOptions)
            .then(response => {
                if (response.status === 401) {
                    this.props.callback.logout()
                    return;
                } else {
                    return response.json()
                }
            })
            .then(data => 
                {
                    this.setState({
                        traderFunctionLoading: false,
                    })
                    
                }
            ).catch(error => {
                console.log(error)
                this.setState({
                    traderFunctionLoading: false,
                })
            });
    }

    render() {

        var summary = this.props.summary

        var startingEquity = 2000000

        if (summary === undefined) {
            return (<div className='loading'><ReactLoading
                type={"bars"}
                color={"#03fc4e"}
                height={100}
                width={100}
              /></div>)
        }

        var adminAlerts;
        if (summary.adminAlerts !== undefined && summary.adminAlerts !== null) {
            if (summary.adminAlerts.length > 0) {
                adminAlerts = <div className='fund-panel'>
                    <div className='fund-panel-title'>
                        Admin Alerts
                    </div>
                    <div className='fund-panel-body'>
                
                    <AdminAlerts alerts={summary.adminAlerts} />
                </div>
            </div>
                
                
            }
        }

        var equity

        var traderFunctions

        if (Utils.isAdmin) {
            equity = 
            <div>
                <div className='panel-divider'></div>
                    <div className='fund-panel-row'>
                    <div className='fund-panel-row-key'>Current NAV</div>
                    <div className={'fund-panel-row-value ' + ""}>${Utils.numberWithCommas((summary.stats.currentNAV), 0)}</div>
                </div>
            </div>


            if (this.props.userDetails.username === 'omar') {
                if (this.state.traderFunctionLoading) {
                    traderFunctions = 
                    <div className='fund-panel'>
                            <div className='fund-panel-title'>
                                Trader Functions
                            </div>
                            <div className='fund-panel-body'>
                            <div className='loading-small'><ReactLoading
                                    type={"bars"}
                                    color={"#03fc4e"}
                                    height={100}
                                    width={100}
                                /></div>
                            </div>
                    </div>
                } else if (this.state.showTraderFunctions) {
                    traderFunctions = 
                    <div className='fund-panel'>
                            <div className='fund-panel-title'>
                                Trader Functions
                            </div>
                            <div className='fund-panel-body' style={{display: 'flex', flexDirection: 'row', columnGap:'10px', flexWrap: 'wrap'}}>
                                <div className='fund-panel-row'>
                                    <div className='trader-function-button' onClick={() => {this.startTrader()}}>Start Trader</div>
                                </div>

                                <div className='fund-panel-row'>
                                    <div className='trader-function-button' onClick={() => {this.subscribeMarkets()}}>Subscribe to Markets</div>
                                </div>

                                <div className='fund-panel-row'>
                                    <div className='trader-function-button' onClick={() => {this.reloadBots()}}>Reload Bots</div>
                                </div>

                                <div className='fund-panel-row'>
                                    <div className='trader-function-button' onClick={() => {this.pauseTrading()}}>Pause Trading</div>
                                </div>

                                <div className='fund-panel-row'>
                                    <div className='trader-function-button' onClick={() => {this.resumeTrading()}}>Resume Trading</div>
                                </div>

                                <div className='fund-panel-row'>
                                    <div className='trader-function-button' onClick={() => {this.logoutTrader()}}>Logout</div>
                                </div>

                                <div className='fund-panel-row'>
                                    <div className='trader-function-button' onClick={() => {this.killTrader()}}>Kill Trader</div>
                                </div>
                            </div>
                    </div>
                } else {
                    traderFunctions = 
                    <div className='fund-panel'>
                            <div className='fund-panel-title'>
                                Trader Functions
                            </div>
                            <div className='fund-panel-body' style={{display: 'flex', flexDirection: 'row', columnGap:'10px', flexWrap: 'wrap'}}>
                                <div className='fund-panel-row'>
                                    <div className='trader-function-button' onClick={() => {this.showTraderFunctions()}}>Show Functions</div>
                                </div>

                            </div>
                    </div>
                }
            }
        }

        var editButton

        if (Utils.isAdmin) {
            editButton = <div className='edit'><img src='edit.png' /></div>
        }

        var riskReductionPnl = summary.stats.riskReductionPnl;// -44035.557365;
        var todaysRealisedExcludingRisk = summary.stats.todaysRealised - riskReductionPnl

        var totalFees = 0

        summary.groups.forEach(group => {
            totalFees += group.fees
        });


        return (<div className='fund-home'>
                    <div className='fund-home-summary'>
                        <div className='fund-panel'>
                            <div className='fund-panel-title'>
                                Today's Stats
                            </div>
                            <div className='fund-panel-body'>
                                <div className='fund-panel-row'>
                                    <div className='fund-panel-row-key'>Realised PNL</div>
                                    <div className='fund-panel-row-value '>
                                        <div className={todaysRealisedExcludingRisk >= 0 ? 'green' : 'red'}>{todaysRealisedExcludingRisk < 0 ? '-' : '+'}${Utils.numberWithCommas(Math.abs(todaysRealisedExcludingRisk), 0)}</div>
                                        <div className='fund-panel-row-value-sub'>({Utils.numberWithCommas((todaysRealisedExcludingRisk/startingEquity)*100, 2)}% of equity)</div>
                                    </div>
                                </div>

                                <div className='fund-panel-row'>
                                    <div className='fund-panel-row-key'>Risk PNL</div>
                                    <div className='fund-panel-row-value '>
                                        <div className={riskReductionPnl >= 0 ? 'green' : 'red'}>{riskReductionPnl < 0 ? '-' : '+'}${Utils.numberWithCommas(Math.abs(riskReductionPnl), 0)}</div>
                                    </div>
                                </div>

                                <div className='fund-panel-row'>
                                    <div className='fund-panel-row-key'>Net PNL</div>
                                    <div className='fund-panel-row-value '>
                                        <div className={summary.stats.todaysRealised >= 0 ? 'green' : 'red'}>{summary.stats.todaysRealised < 0 ? '-' : '+'}${Utils.numberWithCommas(Math.abs(summary.stats.todaysRealised), 0)}</div>
                                        <div className='fund-panel-row-value-sub'>({Utils.numberWithCommas((summary.stats.todaysRealised/startingEquity)*100, 2)}% of equity)</div>
                                    </div>
                                </div>
                                <div className='panel-divider'></div>

                                <div className='fund-panel-row'>
                                    <div className='fund-panel-row-key'>MTD Realised PNL</div>
                                    <div className='fund-panel-row-value '>
                                        <div className={summary.stats.mtdRealised >= 0 ? 'green' : 'red'}>{summary.stats.mtdRealised < 0 ? '-' : '+'}${Utils.numberWithCommas(Math.abs(summary.stats.mtdRealised), 0)}</div>
                                        <div className='fund-panel-row-value-sub'>({Utils.numberWithCommas((summary.stats.mtdRealised/startingEquity)*100, 2)}% of equity)</div>
                                    </div>
                                </div>

                                <div className='fund-panel-row'>
                                    <div className='fund-panel-row-key'>FX Swap {editButton}</div>
                                    <div className={'fund-panel-row-value '+(summary.stats.todaysSwapProfit < 0 ? 'red' : 'green')}>{summary.stats.todaysSwapProfit < 0 ? '-' : '+'}${Utils.numberWithCommas(Math.abs(summary.stats.todaysSwapProfit), 2)}</div>
                                </div>

                                <div className='fund-panel-row'>
                                    <div className='fund-panel-row-key'>NOP</div>
                                    <div className='fund-panel-row-value green'>{Utils.numberWithCommas(summary.nop.utilization, 2)}%</div>
                                </div>

                                <div className='panel-divider'></div>

                                <div className='fund-panel-row'>
                                    <Countdown />
                                </div>

                                <div className='fund-panel-row'>
                                    <div className='fund-panel-row-key'>Volume</div>
                                    <div className='fund-panel-row-value'>${Utils.numberWithCommas(summary.stats.todaysVolume, 0)}</div>
                                </div>

                                <div className='fund-panel-row'>
                                    <div className='fund-panel-row-key'>Number of Trades</div>
                                    <div className='fund-panel-row-value'>{Utils.numberWithCommas(summary.stats.todaysTradeNumber, 0)}</div>
                                </div>

                                <div className='fund-panel-row'>
                                    <div className='fund-panel-row-key'>Fees</div>
                                    <div className='fund-panel-row-value red'>${Utils.numberWithCommas(summary.stats.todaysFees, 2)}</div>
                                </div>

                                <div className='fund-panel-row'>
                                    <div className='fund-panel-row-key'>Cost/million</div>
                                    <div className='fund-panel-row-value red'>${Utils.numberWithCommas(summary.stats.todaysCostPerMillion, 2)}</div>
                                </div>
                                <div className='fund-panel-row'>
                                    <div className='fund-panel-row-key'>Profit/Yard</div>
                                    <div className={'fund-panel-row-value ' + (summary.stats.todaysProfitPerYard < 0 ? 'red' : 'green')}>{summary.stats.todaysProfitPerYard < 0 ? '-' : '+'}${Utils.numberWithCommas(Math.abs(summary.stats.todaysProfitPerYard), 0)}</div>
                                </div>
                            </div>
                        </div>

                        {adminAlerts}

                        {traderFunctions}

                        <div className='fund-panel'>
                            <div className='fund-panel-title'>
                                {this.state.details.name}
                            </div>
                            <div className='fund-panel-body'>
                           
                                <div className='fund-panel-row'>
                                    <div className='fund-panel-row-key'>Starting NAV</div>
                                    <div className='fund-panel-row-value'>${Utils.numberWithCommas(startingEquity, 0)}</div>
                                </div>

                                {equity}
                            </div>
                        </div>

                        <div className='fund-panel'>
                            <div className='fund-panel-title'>
                                Latest Trades
                            </div>
                            <div className='fund-panel-body'>
                           
                                <LatestTrades latestTrades={summary.latestTrades} />
                            </div>
                        </div>

                        <div className='fund-panel'>
                            <div className='fund-panel-title'>
                                Position Ladder
                            </div>
                            <div className='fund-panel-body'>
                                <PositionLadder positionLadder={summary.positionLadder} nop={summary.nop} />
                            </div>
                        </div>

                        <div className='fund-panel'>
                            <div className='fund-panel-title'>
                                PNL
                            </div>
                            <div className='fund-panel-body'>
                                <PNL pnl={summary.pnl} stats={summary.stats} groups={summary.groups} />
                            </div>
                        </div>

                        <div className='fund-panel'>
                            <div className='fund-panel-title'>
                                Unswept Profits
                            </div>
                            <div className='fund-panel-body'>
                                <UnsweptProfits unsweptProfits={summary.pnl.unsweptProfits} />
                            </div>
                        </div>

                        <div className='fund-panel'>
                            <div className='fund-panel-title'>
                                Groups
                            </div>
                            <div className='fund-panel-body'>
                                <GroupsSummary groups={summary.groups} />
                            </div>
                        </div>
                    </div>
                    <div className='fund-home-details'>
                         <GroupDetails groups={summary.groups} />
                    </div>
            </div>)
    }
}

export default Home;