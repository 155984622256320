import React, { Component } from 'react';
import Utils from '../../Utils'
import './Countdown.css'

class Countdown extends Component {
  

    componentDidMount() {

    }


    componentWillUnmount() {
        
    }


    render() {


        const now = new Date(); // Current date and time

        // Set the time zone to UTC and get the current hour and minute
        const currentUTCHour = now.getUTCHours();
        const currentUTCMinutes = now.getUTCMinutes();
        const currentUTCDay = now.getUTCDay(); // Days are 0 (Sunday) - 6 (Saturday)

        // Calculate start date
        const startDate = new Date(now);
        startDate.setUTCHours(22, 0, 0, 0); // Set to 22:00 UTC
        if (currentUTCHour > 22 || (currentUTCHour === 22 && currentUTCMinutes > 0)) {
          // If it's past 22:00, start date is today
          startDate.setUTCDate(startDate.getUTCDate());
        } else {
          // Otherwise, start date is yesterday
          startDate.setUTCDate(startDate.getUTCDate() - 1);
        }
      
        // Set the end date to today at 20:50 UTC
        const endDate = new Date(now);
        if (endDate.getUTCHours() >= 22) {
            endDate.setUTCDate(endDate.getUTCDate() + 1);
        }
        endDate.setUTCHours(20, 50, 0, 0);
      
        // Calculate the difference in milliseconds
        const timeDiff = endDate - now;
        
        // Convert time difference to hours, minutes, and seconds
        const seconds = Math.floor((timeDiff / 1000) % 60);
        const minutes = Math.floor((timeDiff / 1000 / 60) % 60);
        const hours = Math.floor((timeDiff / 1000 / 60 / 60));
      

        const dayStart = startDate.getDate().toString().padStart(2, '0');
        const monthStart = (startDate.getMonth() + 1).toString().padStart(2, '0'); // +1 because months are zero-indexed
        const hourStart = startDate.getHours().toString().padStart(2, '0');
        const minuteStart = startDate.getMinutes().toString().padStart(2, '0');
      

        const dayEnd = endDate.getDate().toString().padStart(2, '0');
        const monthEnd = (endDate.getMonth() + 1).toString().padStart(2, '0'); // +1 because months are zero-indexed
        const hourEnd = endDate.getHours().toString().padStart(2, '0');
        const minuteEnd = endDate.getMinutes().toString().padStart(2, '0');



        var remaining = (hours < 10 ? "0" : "") +  hours + ":" + (minutes < 10 ? "0" : "") + minutes + ":" + (seconds < 10 ? "0" : "") + seconds

        var widthProgress = "0%";

        var tradingProgressDiv;

        if (
            (currentUTCHour === 20 && currentUTCMinutes >= 50) || currentUTCHour === 21 || 
            (currentUTCHour === 22 && currentUTCMinutes === 0) ||
            (currentUTCDay === 5 && (currentUTCHour > 20 || (currentUTCHour === 20 && currentUTCMinutes >= 50))) || // Friday after 20:50 UTC
            currentUTCDay === 6 || // All of Saturday
            (currentUTCDay === 0 && currentUTCHour < 22) // Sunday before 23:00 UTC
          ) {
            remaining = "Market Closed";
            widthProgress = "0%";

            tradingProgressDiv = undefined
          } else {
            const totalDuration = endDate - startDate; // Total duration in milliseconds
            const elapsedTime = now - startDate; // Elapsed time in milliseconds
          
            let progress = (elapsedTime / totalDuration) * 100; // Progress as a percentage
            

            widthProgress = Math.max(0, Math.min(100, progress)) + "%"; // Clamp value between 0 and 100%
          


            tradingProgressDiv = 
                <div className='trading-day-container'>
                    <div className='trading-day-start'>{dayStart}/{monthStart} {hourStart}:{minuteStart}</div>
                    <div className='trading-day-progress'>
                        <div className='trading-day-progress-bar' style={{width: widthProgress}}></div>

                        <div className='trading-day-remaining'>{remaining}</div>
                    </div>
                    <div className='trading-day-end'>{dayEnd}/{monthEnd} {hourEnd}:{minuteEnd}</div>

                </div>
            
          }
  

        return (<div className='countdown-container'>
                {tradingProgressDiv}
                      </div>)
    }
}

export default Countdown;