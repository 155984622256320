import React, { Component } from 'react';
import { Chart, DataTable } from "react-google-charts";
import ReactLoading from "react-loading";
import Utils from '../../Utils';


const optionsServerChart = {
    width: 450,
    height: 120,
    redFrom: 90,
    redTo: 100,
    yellowFrom: 85,
    yellowTo: 90,
    minorTicks: 5,
  };

export const optionsDailyPnlChart = {
    curveType: "function",
    legend: { position: 'none' },
    backgroundColor: 'transparent',
    titleTextStyle: {
        color: '#FF5733', // Example: Orange color for the title text
        fontSize: 20, // You can also set the font size
      },
      // Set horizontal axis text style
      hAxis: {
        textStyle: {
          color: '#fff', // Example: Pink color for horizontal axis text
        },
        gridlines: {
          color: 'none', // Remove vertical grid lines
        },
      },
      // Set vertical axis text style
      vAxis: {
        textStyle: {
          color: '#64FA64', // Example: Green color for vertical axis text
        }
      },
  
      // Set line colors for the chart
      colors: ['#64FA64'],
      tooltip: {
        isHtml: true,
        textStyle: {
            color: '#000', // Tooltip text color
        },
        trigger: 'focus' // Display tooltip when focusing on data point
      },
};

class NAVHistory extends Component {
    
   
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    
    componentDidMount() {
    }
    
    
    componentWillUnmount() {
        
    }
    
    numberWithCommas(x, decimals = 2) {
        return Number.parseFloat(x).toFixed(decimals).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }


      getDailyPnlData() {
        var result = []
        result.push([{ type: 'date', label: 'Date' }, "Realised $"]);

        this.props.dailyReports.forEach((item) => {
          if (item.realisedPnlExcludingRiskReduction > 0) {
            result.push([new Date(item.closeTime), item.realisedPnlExcludingRiskReduction])
          } else {
            result.push([new Date(item.closeTime), item.realisedPnl])
          }
        })
        return result;
      }

      getDailyRiskPnlData() {
        var result = []
        result.push([{ type: 'date', label: 'Date' }, "Risk Realised $"]);
        this.props.dailyReports.forEach((item) => {
            result.push([new Date(item.closeTime), item.realisedPnlRiskReduction])
        })
        return result;
      }


      getDailyVolumeData() {
        var result = []
        result.push([{ type: 'date', label: 'Date' }, "Volume $"]);

        this.props.dailyReports.forEach((item) => {
            result.push([new Date(item.closeTime), item.volume])
        })
        return result;
      }

      getDailyPnlEquityData() {
        var result = []
        result.push([{ type: 'date', label: 'Date' }, "% return on equity"]);
        this.props.dailyReports.forEach((item) => {
          if (item.realisedPnlExcludingRiskReduction > 0) {
            result.push([new Date(item.closeTime), (item.realisedPnlExcludingRiskReduction/item.equity)*100])
          } else {
            result.push([new Date(item.closeTime), (item.realisedPnl/item.equity)*100])
          }
        })
        return result;
      }

      getDailyEquityData() {
        var result = []
        result.push([{ type: 'date', label: 'Date' }, "NAV"]);
        this.props.dailyReports.forEach((item) => {
            result.push([new Date(item.closeTime), item.equity])
        })
        return result;
      }

      getCumulativeNetPnl() {
        var result = []
        result.push([{ type: 'date', label: 'Date' }, "Cumulative Net Pnl"]);
        this.props.dailyReports.forEach((item) => {
            result.push([new Date(item.closeTime), item.cumulativeNetPnl])
        })
        return result;
      }

      getGrowthFactor() {
        var result = []
        result.push([{ type: 'date', label: 'Date' }, "Daily % Return"]);

        this.props.dailyReports.forEach((item) => {
            result.push([new Date(item.closeTime), item.growthFactor])
        })
        return result;
      }

    render() {
        


        if (this.props.dailyReports === undefined) {
            return (<div className='loading'><ReactLoading
                type={"bars"}
                color={"#03fc4e"}
                height={100}
                width={100}
              /></div>)
        }

        var lastEquity = 1
        var lastRealised = 0
        this.props.dailyReports.forEach((item) => {
            lastEquity = item.equity
            lastRealised = item.realisedPnl
        })

        var navPanel

        if (Utils.isAdmin) {
          navPanel = <div className='fund-panel'>
          <div className='fund-panel-title '> Daily NAV</div>


              <Chart
                  chartType="LineChart"
                  width="100%"
                  height="500px"
                  data={this.getDailyEquityData()}
                  options={optionsDailyPnlChart}
                  />

        </div>
        }

       
                    
                    return (

                        <div>
                       
                       <div className='fund-panel'>
                                    <div className='fund-panel-title '> Daily % Return</div>


                                        <Chart
                                            chartType="LineChart"
                                            width="100%"
                                            height="500px"
                                            data={this.getGrowthFactor()}
                                            options={optionsDailyPnlChart}
                                            />

                            </div>

                            <div className='fund-panel'>
                                    <div className='fund-panel-title '> Cumulative Net Pnl</div>


                                        <Chart
                                            chartType="LineChart"
                                            width="100%"
                                            height="500px"
                                            data={this.getCumulativeNetPnl()}
                                            options={optionsDailyPnlChart}
                                            />

                            </div>


                        

                            <div className='fund-panel'>
                                    <div className='fund-panel-title '> Daily % Return on Equity</div>


                                        <Chart
                                            chartType="LineChart"
                                            width="100%"
                                            height="500px"
                                            data={this.getDailyPnlEquityData()}
                                            options={optionsDailyPnlChart}
                                            />

                            </div>

                            <div className='fund-panel'>
                                    <div className='fund-panel-title '> Daily PNL</div>


                                        <Chart
                                            chartType="LineChart"
                                            width="100%"
                                            height="500px"
                                            data={this.getDailyPnlData()}
                                            options={optionsDailyPnlChart}
                                            />

                            </div>

                            <div className='fund-panel'>
                                    <div className='fund-panel-title '> Daily Risk Reduction PNL</div>


                                        <Chart
                                            chartType="LineChart"
                                            width="100%"
                                            height="500px"
                                            data={this.getDailyRiskPnlData()}
                                            options={optionsDailyPnlChart}
                                            />

                            </div>

                            <div className='fund-panel'>
                                    <div className='fund-panel-title '> Daily Volume</div>


                                        <Chart
                                            chartType="LineChart"
                                            width="100%"
                                            height="500px"
                                            data={this.getDailyVolumeData()}
                                            options={optionsDailyPnlChart}
                                            />

                            </div>

                          {navPanel}

                          

                            

                        </div>
                    


                        
                        
                        );
                }
            }              
export default NAVHistory;