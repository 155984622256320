// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.adminAlerts {

    
    max-height: 320px;
    overflow: auto;

    display: flex;
    flex-direction: column;
    width: 100%;;
}

.alert {
    text-align: left;
    margin-top: 5px;
    padding: 3px;;
    padding-bottom: 10px;
    border-bottom: 1px solid #444;
}

.alert:hover {
    background-color: #000;
}

.alert-title {
    font-size: 18px;;
}

.alert-description {
    margin-top:3px;
}`, "",{"version":3,"sources":["webpack://./src/Fund/Home/AdminAlerts.css"],"names":[],"mappings":"AAAA;;;IAGI,iBAAiB;IACjB,cAAc;;IAEd,aAAa;IACb,sBAAsB;IACtB,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,YAAY;IACZ,oBAAoB;IACpB,6BAA6B;AACjC;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".adminAlerts {\n\n    \n    max-height: 320px;\n    overflow: auto;\n\n    display: flex;\n    flex-direction: column;\n    width: 100%;;\n}\n\n.alert {\n    text-align: left;\n    margin-top: 5px;\n    padding: 3px;;\n    padding-bottom: 10px;\n    border-bottom: 1px solid #444;\n}\n\n.alert:hover {\n    background-color: #000;\n}\n\n.alert-title {\n    font-size: 18px;;\n}\n\n.alert-description {\n    margin-top:3px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
