import React, { Component } from 'react';
import Utils from '../../Utils'
import './PricingSpeeds.css'

class PricingSpeeds extends Component {
    constructor(props) {
        super(props);

        this.state = {
            details: props.details
        }
    }

    componentDidMount() {

    }


    componentWillUnmount() {
        
    }


    render() {
        var pricingSpeeds = this.props.pricingSpeeds

        let speeds = []

        var totalRates = 0
        var averageProcessSpeed = 0

        pricingSpeeds.sort(
            function(a,b) { 
                if (a === undefined || b === undefined) { 
                    return 0 
                }
                return a.lp.localeCompare(b.lp)
            }
                ).forEach((pos) => {
                    var speed = pos

                    totalRates += speed.pricesPerSecond
                    averageProcessSpeed += speed.rateProcessTimeNanoSeconds
                    speeds.push(
                        <div className='pricingSpeed'>
                            <div className='pricingSpeed-lp'>
                            {Utils.lpName(speed.lp)}
                            </div>

                            <div className='pricingSpeed-details'>
                                <div className='pricingSpeed-speed'>
                                    {Utils.numberWithCommas(speed.pricesPerSecond, 0)} prices/s
                                </div>
                                <div className={'pricingSpeed-processtime ' + ''}>
                                    {Utils.numberWithCommas(speed.rateProcessTimeNanoSeconds/1000, 0)} μs/price
                                </div>
                            </div>
                        </div>
                        )
                    });

        if (pricingSpeeds.length > 0) {
            averageProcessSpeed /= pricingSpeeds.length
        }

        speeds.push(
            <div className='pricingSpeed'>
                <div className='pricingSpeed-lp'>
                Total
                </div>

                <div className='pricingSpeed-details'>
                    <div className='pricingSpeed-speed'>
                        {Utils.numberWithCommas(totalRates, 0)} prices/s
                    </div>
                    <div className={'pricingSpeed-processtime ' + ''}>
                        {Utils.numberWithCommas(averageProcessSpeed/1000, 0)} μs/price
                    </div>
                </div>
            </div>
            )

        return (<div className='positionLadder'>
            {speeds}
                      </div>)

    }
}

export default PricingSpeeds;