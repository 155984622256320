import React, { Component } from 'react';
import './Prices.css'
import ReactLoading from "react-loading";
import Utils from '../../Utils';
import PricingSpeeds from './PricingSpeeds';

class Prices extends Component {
    
    constructor(props) {
        super(props);
        
    }
    
    componentDidMount() {
    }

    
    
    componentWillUnmount() {
        
    }

    numberWithCommas(x, decimals = 2) {
        return Number.parseFloat(x).toFixed(decimals).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    render() {
        var prices = this.props.prices

        if (prices === undefined) {
            return (<div className='loading'><ReactLoading
                type={"bars"}
                color={"#03fc4e"}
                height={100}
                width={100}
              /></div>)
        }

        console.log(prices)
        if (prices === undefined) {
            return "No prices"
        }

        var result = [];

        Object.keys(prices).sort().forEach(key => {
            
            var multiplier = 10000
            if (prices[key].symbol.includes("JPY")) {
                multiplier = 100
            }

            var spread = prices[key].asks[0].price - prices[key].bids[0].price

            spread *= multiplier
            spread = Math.round(spread*10)/10.0;


            var asks = []

            var bids = []

            prices[key].asks.forEach(level => {
                asks.unshift(<div  className='prices-ask'>
                        <div  className='prices-ask-price'>{level.price}</div>
                        <div  className='prices-ask-quantity'>{this.numberWithCommas(level.quantity, 0)}</div>
                        <div className='prices-ask-exchange'><img src={level.exchange + '.png'} /> {Utils.lpName(level.exchange)}</div>
                    </div>)
            })

            prices[key].bids.forEach(level => {
                bids.push(<div  className='prices-bid'>
                        <div  className='prices-bid-price'>{level.price}</div>
                        <div  className='prices-bid-quantity'>{this.numberWithCommas(level.quantity, 0)}</div>
                        <div className='prices-bid-exchange'><img src={level.exchange + '.png'} /> {Utils.lpName(level.exchange)}</div>
                    </div>)
            })

            result.push(
                <div className='prices'>

                    <div className='prices-symbol'>{prices[key].symbol}</div>
                    <div className='prices-price'>
                        <div  className='prices-asks'>
                            {asks}
                        </div>
                        <div  className='prices-spread'>{spread}</div>
                        <div  className='prices-bids'>
                            {bids}
                        </div>
                    </div>

                </div>


            );

        });

        return (
            <div>
                <div className='prices-container'>
                <div className='fund-panel' style={{flex: '1', minWidth:'330px', maxHeight:'auto', overflow:'auto'}}>
                    <div className='fund-panel-title'>
                        Pricing Speeds
                    </div>
                    <div className='fund-panel-body'>
                        <PricingSpeeds pricingSpeeds={this.props.pricingSpeeds} />
                    </div>
                </div>

                
                
                {result}
                </div>
            </div>)
    }
}
    
    export default Prices;