import React, { Component } from 'react';
import Utils from '../Utils'
import './Fund.css'
import Home from './Home/Home';
import Prices from './Prices/Prices'
import History from './History/History';
import Ranks from './Ranks/Ranks';
import Analytics from './Analytics/Analytics';

class Fund extends Component {

    lastHeartbeat = 0

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            summary: undefined,
            details: props.details,
            tab: "home"
        }
    }

    componentDidMount() {
        this.loadFundSummary();
    }

    logout() {
        this.props.callback.logout()
    }

    loadFundSummary() {
        var token = Utils.getToken()
        if (token === null) {
            this.props.callback.logout()
            return;
        }

        var currentTime = new Date().getTime()

        if ((currentTime - this.lastHeartbeat)/1000 >= 60) {
            Utils.sendEvent("HEARTBEAT")
            this.lastHeartbeat = currentTime
        }

        const requestOptions = {
            method: 'GET',
            headers: { 
            "Authorization": "Bearer " + token
             }
        };
        fetch('https://api-dashboard.goodmarkets.com/Dashboard/Fund/Summary/'+this.state.details.id, requestOptions)
            .then(response => {
                if (response.status === 401) {
                    this.props.callback.logout()
                    return;
                } else {
                    return response.json()
                }
            })
            .then(data => 
                {
                    this.setState({
                        summary: data,
                    })
                    setTimeout(() => { this.loadFundSummary() }, 1000)
                    
                }
            ).catch(error => {
                console.log(error)
                setTimeout(() => { this.loadFundSummary() }, 1000)
            });
    }

    componentWillUnmount() {
        
    }

    selectTab(tab) {
        this.setState({
            tab: tab
        })
        Utils.sendEvent("TAB_"+tab.toUpperCase())
    }

    render() {

        var body;

        if (this.state.tab === "home") {
            body = <Home callback={this} details={this.state.details} summary={this.state.summary} userDetails={this.props.userDetails} />
        } else if (this.state.tab === "prices") {
            body = <Prices prices={this.state.summary.prices} pricingSpeeds={this.state.summary.pricingSpeeds}  />
        }  else if (this.state.tab === "history") {
            body = <History dailyReports={this.state.summary.dailyReports} />
        } else if (this.state.tab === "analytics") {
            body = <Analytics summary={this.state.summary} details={this.state.details} callback={this}  />
        } else if (this.state.tab === "ranks") {
            body = <Ranks 
            historicExchangeRanks={this.state.summary.rankings.historicExchangeRanks} 
            symbolExchangeRanks={this.state.summary.rankings.symbolExchangeRanks} 
            exchangeAcceptance={this.state.summary.rankings.exchangeAcceptance} 
            exchangeAcceptanceHour={this.state.summary.rankings.exchangeAcceptanceHour} 
            />
        }

        var historyTab = <div className={'fund-header-button ' + (this.state.tab === "history" ? "fund-header-button-selected" : "")}
                            onClick={()=>{this.selectTab("history")}}>
                            History
                        </div>

        if (Utils.isAdmin) {
            historyTab = <div className={'fund-header-button ' + (this.state.tab === "analytics" ? "fund-header-button-selected" : "")}
                                onClick={()=>{this.selectTab("analytics")}}>
                                Analytics
                            </div>
        }

        return (<div className='fund'>
                    <div className='fund-header'>
                        <div className={'fund-header-button ' + (this.state.tab === "home" ? "fund-header-button-selected" : "")}
                            onClick={()=>{this.selectTab("home")}}>
                            Home
                        </div>
                        <div className={'fund-header-button ' + (this.state.tab === "prices" ? "fund-header-button-selected" : "")}
                            onClick={()=>{this.selectTab("prices")}}>
                            Prices
                        </div>

                        {historyTab}
                        
                        <div className={'fund-header-button ' + (this.state.tab === "ranks" ? "fund-header-button-selected" : "")}
                            onClick={()=>{this.selectTab("ranks")}}>
                            Ranks
                        </div>
                    </div>
                    <div className='fund-body'>{body}</div>
            </div>)
    }
}

export default Fund;