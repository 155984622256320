import logo from './logo.svg';
import './App.css';
import Login from './Auth/Login';
import { Component } from 'react';
import Dashboard from './Dashboard/Dashboard';
import ReactLoading from "react-loading";
import Utils from './Utils';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: false,
      refreshingToken: false
    }

    if (localStorage.getItem("token") !== null) {
        var token = JSON.parse(localStorage.getItem("token"))
        var expiration = new Date(token.expiration)
        var now = new Date()
        if (now > expiration) {
          this.state.loggedIn = false
          localStorage.clear()
        } else {
          this.state.loggedIn = true
          this.refreshToken();
        }
    }

  }

  componentDidMount() {
    if (this.state.loggedIn) {
      this.refreshToken();
    }
  }

  setLoggedIn() {
    this.setState({
      loggedIn: true
    })
  }
  
  setLoggedOut() {
    localStorage.clear()

    this.setState({
      loggedIn: false
    })
    Utils.isAdmin = false
    
  }

  refreshToken() {
    this.setState({
      refreshingToken: true
    })

    var token = Utils.getToken()
    if (token === null) {
        this.setLoggedOut()
        return;
    }

    const requestOptions = {
        method: 'POST',
        headers: { 
        "Authorization": "Bearer " + token
          }
    };
    fetch('https://api-dashboard.goodmarkets.com/Auth/Refresh', requestOptions)
        .then(response => {
            if (response.status === 401) {
                this.setLoggedOut()
                this.setState({
                  refreshingToken: false
                })
                return;
            } else {
                return response.json()
            }
        })
        .then(data => 
            {
              if (data.jwt !== undefined && data.expiration !== undefined) {
                  localStorage.setItem("token", JSON.stringify(data))
                  // callback for the login
                  this.setLoggedIn()
                  this.setState({
                    refreshingToken: false
                  })
              } else {
                  this.setLoggedOut()
                  this.setState({
                    refreshingToken: false
                  })
              }

            }
        ).catch(error => {
            console.log(error)
        });
  }

  render() {

    if (this.state.refreshingToken) {
      return (<div className='loading'><ReactLoading
          type={"bars"}
          color={"#03fc4e"}
          height={100}
          width={100}
        /></div>)
  }

    var app;
    if (this.state.loggedIn) {
      // run dashboard
      app = <Dashboard callback={this} />
    } else {
      app =  <Login callback={this} />
    }

    return (
      <div className="App">
        {app}
      </div>
    );
  } 
}
export default App;
