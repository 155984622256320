// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cycles {
    display: flex;
    text-align: left;
}

.cycles-cell {
    flex: 1 1;
    padding: 5px;
}

.cycles-title {
    font-weight: bold;
    font-size: 18px;
}

.cycles-currency {
    font-weight: bold;
}



.cycles-direction {
    max-width: 100px;
}

.cycles-symbol {
    max-width: 100px;
}

.cycle-functions {
    display: flex;
    text-align: right;
}

.cycles-export {
    width: 100px;
    border: 1px solid #fff;
    padding: 5px;;
    border-radius: 8px;;
    margin-left:auto;
    margin-right: 10px;;
    -webkit-user-select: none;;
            user-select: none;
    cursor: pointer;
    text-align: center;
}

.cycles-export:hover {
    background-color: #fff;
    color: #000;
}`, "",{"version":3,"sources":["webpack://./src/Fund/Analytics/AnalyticsCycles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,SAAa;IACb,YAAY;AAChB;;AAEA;IACI,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,iBAAiB;AACrB;;;;AAIA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,sBAAsB;IACtB,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;IAChB,kBAAkB;IAClB,yBAAiB;YAAjB,iBAAiB;IACjB,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,sBAAsB;IACtB,WAAW;AACf","sourcesContent":[".cycles {\n    display: flex;\n    text-align: left;\n}\n\n.cycles-cell {\n    flex: 1 1 0px;\n    padding: 5px;\n}\n\n.cycles-title {\n    font-weight: bold;\n    font-size: 18px;\n}\n\n.cycles-currency {\n    font-weight: bold;\n}\n\n\n\n.cycles-direction {\n    max-width: 100px;\n}\n\n.cycles-symbol {\n    max-width: 100px;\n}\n\n.cycle-functions {\n    display: flex;\n    text-align: right;\n}\n\n.cycles-export {\n    width: 100px;\n    border: 1px solid #fff;\n    padding: 5px;;\n    border-radius: 8px;;\n    margin-left:auto;\n    margin-right: 10px;;\n    user-select: none;\n    cursor: pointer;\n    text-align: center;\n}\n\n.cycles-export:hover {\n    background-color: #fff;\n    color: #000;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
