import React, { Component } from 'react';
import Utils from '../../Utils'
import './PNL.css'

class PNL extends Component {
    
    componentDidMount() {

    }


    componentWillUnmount() {
        
    }


    render() {

        var pnl = this.props.pnl

        var totalFees = 0

        this.props.groups.forEach(group => {
            totalFees += group.fees
        });

        var equity

        if (Utils.isAdmin) {
            equity = 
            <div>
            <div className='panel-divider'></div>
                <div className='fund-panel-row'>
                <div className='fund-panel-row-key'>Current NAV</div>
                <div className={'fund-panel-row-value ' + ""}>${Utils.numberWithCommas((this.props.stats.currentNAV), 0)}</div>
            </div>
        </div>
        }

        var totalNet = pnl.totalRealised + pnl.totalUnrealised - totalFees

        return (<div className='PNL'>

                    <div className='fund-panel-row'>
                        <div className='fund-panel-row-key'>Net</div>
                        <div className={'fund-panel-row-value ' + (pnl.netPnl < 0 ? 'red' : 'green')}>{pnl.netPnl < 0 ? "-" : ""}${Utils.numberWithCommas(Math.abs(pnl.netPnl), 2)}</div>
                    </div>

                    <div className='fund-panel-row'>
                        <div className='fund-panel-row-key'>Cycle PNL</div>
                        <div className={'fund-panel-row-value ' + (pnl.cyclePnl < 0 ? 'red' : 'green')}>{pnl.cyclePnl < 0 ? "-" : ""}${Utils.numberWithCommas(Math.abs(pnl.cyclePnl), 2)}</div>
                    </div>

                    <div className='fund-panel-row'>
                        <div className='fund-panel-row-key'>Realised PNL</div>
                        <div className={'fund-panel-row-value ' + (pnl.realisedPnl < 0 ? 'red' : 'green')}>{pnl.realisedPnl < 0 ? "-" : ""}${Utils.numberWithCommas(Math.abs(pnl.realisedPnl), 2)}</div>
                    </div>

                    <div className='fund-panel-row'>
                        <div className='fund-panel-row-key'>Pending PNL</div>
                        <div className={'fund-panel-row-value ' + (pnl.pendingPnl < 0 ? 'red' : 'green')}>{pnl.pendingPnl < 0 ? "-" : ""}${Utils.numberWithCommas(Math.abs(pnl.pendingPnl), 2)}</div>
                    </div>

                    <div className='panel-divider'></div>

                    <div className='fund-panel-row'>
                        <div className='fund-panel-row-key'>Total Realised</div>
                        <div className={'fund-panel-row-value ' + (pnl.totalRealised < 0 ? 'red' : 'green')}>{pnl.totalRealised < 0 ? "-" : ""}${Utils.numberWithCommas(Math.abs(pnl.totalRealised), 2)}</div>
                    </div>

                    <div className='fund-panel-row'>
                        <div className='fund-panel-row-key'>Total Unrealised</div>
                        <div className={'fund-panel-row-value ' + (pnl.totalUnrealised < 0 ? 'red' : 'green')}>{pnl.totalUnrealised < 0 ? "-" : ""}${Utils.numberWithCommas(Math.abs(pnl.totalUnrealised), 2)}</div>
                    </div>

                    <div className='fund-panel-row'>
                        <div className='fund-panel-row-key'>Total Fees</div>
                        <div className={'fund-panel-row-value red'}>{pnl.totalUnrealised < 0 ? "-" : ""}${Utils.numberWithCommas(Math.abs(totalFees), 2)}</div>
                    </div>

                    <div className='fund-panel-row'>
                        <div className='fund-panel-row-key'>Total Net</div>
                        <div className={'fund-panel-row-value ' + (totalNet < 0 ? 'red' : 'green')}>{totalNet < 0 ? "-" : ""}${Utils.numberWithCommas(Math.abs(totalNet), 2)}</div>
                    </div>

                    {equity}

                      </div>)
    }
}

export default PNL;