class Utils {

    static isAdmin = false


    static scroll(id) {
        document.getElementById(id)?.scrollIntoView({behavior: 'smooth'})
    }

    static numberWithCommas(x, decimals = 2) {
        return Number.parseFloat(x).toFixed(decimals).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    static getTimeDifference(date1, date2) {
        // Calculate the difference in milliseconds
        const diffInMs = Math.abs(date2 - date1);
        
        // Calculate the difference in various units
        const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
        const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));
        const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
        
        // Determine the appropriate format to display
        if (diffInDays > 0) {
          const hours = Math.floor((diffInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const minutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60));
          return `${diffInDays} ${diffInDays === 1 ? 'day' : 'days'}, ${hours} ${hours === 1 ? 'hour' : 'hours'}, ${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`;
        } else if (diffInHours > 0) {
          const minutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60));
          return `${diffInHours} ${diffInHours === 1 ? 'hour' : 'hours'}, ${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`;
        } else {
          return `${diffInMinutes} ${diffInMinutes === 1 ? 'minute' : 'minutes'}`;
        }
      }

    static getToken() {
        if (localStorage.getItem("token") !== null) {
            var token = JSON.parse(localStorage.getItem("token"))
            var expiration = new Date(token.expiration)
            var now = new Date()
            if (now > expiration) {
                localStorage.clear()
                return null
            } else {
                return token.jwt
            }
        }
        return null
    }

    static sendEvent(name) {
        
        if (localStorage.getItem("did") === null) {
            localStorage.setItem("did", Utils.generateUUID())
        }
        var deviceId = localStorage.getItem("did")

        var token = Utils.getToken()

        const requestOptions = {
            method: 'POST',
            headers: { 
            "Authorization": "Bearer " + token,
            'Content-Type': 'application/json' 
             },
            body: JSON.stringify({ id: deviceId, event: name })
        };

        fetch('https://api-dashboard.goodmarkets.com/Dashboard/Event', requestOptions)
            .then(response => {
                if (response.status === 401) {
                    this.props.callback.setLoggedOut()
                    return;
                } 
            }).catch(error => {
                console.log(error)
            });

    }

    static generateUUID() { // Public Domain/MIT
        var d = new Date().getTime();//Timestamp
        var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random() * 16;//random number between 0 and 16
            if(d > 0){//Use timestamp until depleted
                r = (d + r)%16 | 0;
                d = Math.floor(d/16);
            } else {//Use microseconds since page-load if supported
                r = (d2 + r)%16 | 0;
                d2 = Math.floor(d2/16);
            }
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    }

    static lpName(name) {
        var mappings = {
            "lmax" : "LMAX",
            "gts" : "GTS",
            "gs" : "Goldman Sachs",
            "statestreet" : "State Street",
            "solid" : "Solid FX",
            "ubs" : "UBS",
            "natwest" : "NatWest",
            "threesixtygtx" : "360TGTX",
            "nomura" : "Nomura",
            "sucden" : "Sucden" 
        }

        if (mappings[name] !== undefined) {
            return mappings[name]
        }
        return name
    }
}

export default Utils;