import React, { Component } from 'react';
import { Chart } from "react-google-charts";
import ReactLoading from "react-loading";
import Utils from '../../Utils';
import NAVHistory from './NAVHistory';
import './AnalyticsCycles.css';


class AnalyticsCycles extends Component {


    constructor(props) {
        super(props);
        this.state = {
            details: props.details,
            cycles : undefined
        };
    }
    
    componentDidMount() {


        var token = Utils.getToken()
        if (token === null) {
            this.props.callback.logout()
            return;
        }

        

        const requestOptions = {
            method: 'GET',
            headers: { 
            "Authorization": "Bearer " + token
             }
        };
        fetch('https://api-dashboard.goodmarkets.com/Analytics/GetLatestCycles/'+this.state.details.id, requestOptions)
            .then(response => {
                if (response.status === 401) {
                    this.props.callback.logout()
                    return;
                } else {
                    return response.json()
                }
            })
            .then(data => 
                {
                    this.setState({
                        cycles: data,
                    })
                    
                }
            ).catch(error => {
                console.log(error)
               
            });
    }
    
    
    componentWillUnmount() {
        
    }
    
    numberWithCommas(x, decimals = 2) {
        return Number.parseFloat(x).toFixed(decimals).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    
    getProfitsSinceRRChange() {
        var sum = 0

        var startDate = new Date("2024-07-15T15:00:00.000Z")
        this.state.cycles.forEach(cycle => {
            var start = new Date(cycle.start)
            if (start >= startDate) {
                sum += cycle.pnlUsd
            }
        })
        return sum
    }

    getLossesSinceRRChange() {
        var sum = 0

        var startDate = new Date("2024-07-15T15:00:00.000Z")
        this.state.cycles.forEach(cycle => {
            var start = new Date(cycle.start)
            if (start >= startDate && cycle.pnlUsd < 0) {
                sum += cycle.pnlUsd
            }
        })
        return sum
    }

    export() {
        const { cycles } = this.state;

        // Define the header
        const header = ['symbol', 'direction', 'start', 'end', 'pnl', 'pnlUsd'];

        // Convert the cycles array to CSV format
        const csvContent = [
        header.join(','), // Header row
        ...cycles.map(cycle =>
            [
            cycle.symbol,
            cycle.direction,
            cycle.start,
            cycle.end,
            cycle.pnl,
            cycle.pnlUsd
            ].join(',')
        )
        ].join('\n');

        // Create a Blob from the CSV content
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

        // Create a link element
        const link = document.createElement('a');
        if (link.download !== undefined) {
        // Set the URL using createObjectURL
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'cycles.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        }
    }

    render() {
        


        if (this.state.cycles === undefined) {
            return <div className='loading'><ReactLoading
                type={"bars"}
                color={"#03fc4e"}
                height={100}
                width={100}
            /></div>
        }

        var cycles = []

        cycles.push(
            <div className='cycles'>
                <div className='cycles-cell cycles-title cycles-symbol'>Symbol</div>
                <div className='cycles-cell cycles-title cycles-direction'>Direction</div>
                <div className='cycles-cell cycles-title'>Start</div>
                <div className='cycles-cell cycles-title'>End</div>
                <div className='cycles-cell cycles-title'>PNL</div>
                <div className='cycles-cell cycles-title'>PNL USD</div>
            </div>

        );
        this.state.cycles.forEach(cycle => {
            
            cycles.push(
                <div className='cycles'>
                    <div className='cycles-cell cycles-symbol'>{cycle.symbol}</div>
                    <div className={'cycles-cell cycles-direction ' + (cycle.direction === "LONG" ? 'green' : 'red')}>{cycle.direction}</div>
                    <div className='cycles-cell cycles-start'>{new Date(cycle.start).toLocaleString()}</div>
                    <div className='cycles-cell cycles-end'>{new Date(cycle.end).toLocaleString()}</div>
                    <div className={'cycles-cell cycles-pnl ' + (cycle.pnl >= 0 ? 'green' : 'red')}>{this.numberWithCommas(cycle.pnl, 2)} {cycle.pnlCurrency}</div>
                    <div className={'cycles-cell cycles-pnlusd ' + (cycle.pnlUsd >= 0 ? 'green' : 'red')}>{cycle.pnlUsd < 0 ? '-' : '+'}${Utils.numberWithCommas(Math.abs(cycle.pnlUsd), 2)}</div>
                </div>
            )

        });
   
                
        var profitsSinceChange = this.getProfitsSinceRRChange();

        return (
            <div>
            
                <div className='fund-home'>
                    <div className='fund-home-summary'>
                        <div className='fund-panel'>
                            <div className='fund-panel-title'>
                                Cycle Analytics
                            </div>
                            <div className='fund-panel-body'>
                            

                                <div className='fund-panel-row'>
                                    <div className='fund-panel-row-key'>PNL since change</div>
                                    <div className={'fund-panel-row-value ' + (profitsSinceChange >= 0 ? 'green' : 'red')}>{this.numberWithCommas(profitsSinceChange, 2)}</div>
                                </div>
                               
                                <div className='fund-panel-row'>
                                    <div className='fund-panel-row-key'>Losses since change</div>
                                    <div className={'fund-panel-row-value red'}>{this.numberWithCommas(this.getLossesSinceRRChange(), 2)}</div>
                                </div>
                                
        
                            </div>
                        </div>
                    </div>
                    <div className='fund-home-details'>
                        <div className='cycle-functions'>
                            <div className='cycles-export' onClick={()=>{this.export()}}>Export</div>
                        </div>
                        {cycles}
                    </div>
                </div>
            </div>
            );
    }


}

export default AnalyticsCycles;