// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.positionLadder {

}
.position {
    clear: both;
    padding: 5px;
   
}


.position-currency {
    float: left;
    font-weight: bold;
    line-height: 45px;
    font-size: 18px;
}

.position-details {
    float: right;
}

.position-details-amount {
    text-align: right;
    padding: 2px;
    font-size: 20px;;
}

.position-details-amountusd {
    text-align: right;
    font-size: 15px;;
    margin-bottom: 5px;;
}`, "",{"version":3,"sources":["webpack://./src/Fund/Home/PositionLadder.css"],"names":[],"mappings":";AACA;;AAEA;AACA;IACI,WAAW;IACX,YAAY;;AAEhB;;;AAGA;IACI,WAAW;IACX,iBAAiB;IACjB,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,iBAAiB;IACjB,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,kBAAkB;AACtB","sourcesContent":["\n.positionLadder {\n\n}\n.position {\n    clear: both;\n    padding: 5px;\n   \n}\n\n\n.position-currency {\n    float: left;\n    font-weight: bold;\n    line-height: 45px;\n    font-size: 18px;\n}\n\n.position-details {\n    float: right;\n}\n\n.position-details-amount {\n    text-align: right;\n    padding: 2px;\n    font-size: 20px;;\n}\n\n.position-details-amountusd {\n    text-align: right;\n    font-size: 15px;;\n    margin-bottom: 5px;;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
