import React, { Component } from 'react';
import Utils from '../../Utils'
import ReactLoading from "react-loading";

class Bot extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showUpdateConfig: false,
            loadingUpdateConfig: false,
            selectedConfig: "",
            selectedDivider: 50,
            riskReductionLevel:0,

            showCycles: false,
            cycles:undefined,

            showTrades : false,
            trades:undefined
        }
    }



    componentDidMount() {
        var bot = this.props.bot

        this.state.selectedConfig = bot.configFileName;
        this.state.selectedDivider = bot.configDivider;
        this.state.riskReductionLevel = bot.riskReductionLevel;

    }


    componentWillUnmount() {
        
    }

    saveRiskReductionLevel() {
        var bot = this.props.bot

        var token = Utils.getToken()
        if (token === null) {
            return;
        }

        this.setState({
            loadingUpdateConfig: true,
        })

        const requestOptions = {
            method: 'GET',
            headers: { 
            "Authorization": "Bearer " + token
             }
        };
        fetch('https://api-dashboard.goodmarkets.com/Trader/UpdateRiskReductionLevel/'+bot.id+'/'+this.state.riskReductionLevel, requestOptions)
            .then(response => {
                if (response.status === 401) {
                    this.props.callback.logout()
                    return;
                } else {
                    return response.json()
                }
            })
            .then(data => 
                {
                    this.setState({
                        loadingUpdateConfig: false,
                        showUpdateConfig: false
                    })
                    
                }
            ).catch(error => {
                console.log(error)
                this.setState({
                    loadingUpdateConfig: false,
                })
            });
    }

    saveUpdateConfig() {
        var bot = this.props.bot

        var token = Utils.getToken()
        if (token === null) {
            return;
        }

        this.setState({
            loadingUpdateConfig: true,
        })

        const requestOptions = {
            method: 'GET',
            headers: { 
            "Authorization": "Bearer " + token
             }
        };
        fetch('https://api-dashboard.goodmarkets.com/Trader/UpdateBotConfig/'+bot.id+'/'+this.state.selectedConfig + '/' + this.state.selectedDivider, requestOptions)
            .then(response => {
                if (response.status === 401) {
                    this.props.callback.logout()
                    return;
                } else {
                    return response.json()
                }
            })
            .then(data => 
                {
                    this.setState({
                        loadingUpdateConfig: false,
                        showUpdateConfig: false
                    })
                    
                }
            ).catch(error => {
                console.log(error)
                this.setState({
                    loadingUpdateConfig: false,
                })
            });
    }

    showCycles() {
        if (this.state.showCycles) {
            this.setState({
                showCycles: false,
                cycles: undefined,
                showTrades:false,
                trade:undefined
            });
            return;
        }

        this.setState({
            showCycles: true,
        });

        var bot = this.props.bot

        var token = Utils.getToken()
        if (token === null) {
            return;
        }


        const requestOptions = {
            method: 'GET',
            headers: { 
            "Authorization": "Bearer " + token
             }
        };
        fetch('https://api-dashboard.goodmarkets.com/Dashboard/CompletedCycles/'+bot.id, requestOptions)
            .then(response => {
                if (response.status === 401) {
                    this.props.callback.logout()
                    return;
                } else {
                    return response.json()
                }
            })
            .then(data => 
                {
                    this.setState({
                        cycles: data
                    })
                    
                }
            ).catch(error => {
                console.log(error)
                
            });

    }

    showTrades(cycleId) {
        

        this.setState({
            showTrades: true,
        });

        var bot = this.props.bot

        var token = Utils.getToken()
        if (token === null) {
            return;
        }


        const requestOptions = {
            method: 'GET',
            headers: { 
            "Authorization": "Bearer " + token
             }
        };
        fetch('https://api-dashboard.goodmarkets.com/Dashboard/CycleTrades/'+bot.id+'/'+cycleId, requestOptions)
            .then(response => {
                if (response.status === 401) {
                    this.props.callback.logout()
                    return;
                } else {
                    return response.json()
                }
            })
            .then(data => 
                {
                    this.setState({
                        trades: data
                    })
                    
                }
            ).catch(error => {
                console.log(error)
                
            });

    }

    hideTrades() {
        this.setState({
            trades:undefined,
            showTrades:false
        })
    }

    showUpdateConfig() {
        this.setState({
            showUpdateConfig : !this.state.showUpdateConfig
        })
    }

    updateSelectedConfig(event) {
        this.setState({
            selectedConfig: event.target.value
        }) 
    }


    updateSelectedDivider(event) {
        this.setState({
            selectedDivider: event.target.value
        }) 
    }

    updateRiskReductionLevel(event) {
        this.setState({
            riskReductionLevel: event.target.value
        }) 
    }

    render() {

        var bot = this.props.bot

        var traderFunctions

        if (Utils.isAdmin) {
            traderFunctions = 
               
                            <div className='fund-panel-row'>
                                <div className='trader-function-button' onClick={() => {this.showCycles()}}>Cycles</div>

                                <div className='trader-function-button' onClick={() => {this.showUpdateConfig()}}>Update Config</div>
                            </div>

        }

        var updateConfigPanel;

        var cyclesPanel;

        if (this.state.showUpdateConfig) {

            var a1Configs = [
                "alpha-wave-1-100L-25KP.csv",
                "alpha-wave-1-100L-50KP.csv",
                "alpha-wave-1-100L-HKP.csv",
                "alpha-wave-1-150L-175L-50K-ext.csv",
                "alpha-wave-1-150L-200L-50K-ext.csv",
                "alpha-wave-1-150L-HKP.csv",
                "alpha-wave-1-150L.csv",
                "alpha-wave-1-200L-0KP.csv",
                "alpha-wave-1-200L-250L-50KP-ext.csv",
                "alpha-wave-1-200L-250L-EXT-HKP.csv",
                "alpha-wave-1-200L-250L-50KP-ext.csv",
                "alpha-wave-1-200L-HKP.csv",
                "alpha-wave-1-200L.csv",
                "alpha-wave-1.5-100L-50KP.csv",
                "alpha-wave-1.5-150L-50KP.csv",
                "alpha-wave-1.5-200L-50KP.csv"
                
            ]

            var a2Configs = [
                "alpha-wave-2-100L-125L-ext.csv",
                "alpha-wave-2-100L-150L-ext.csv",
                "alpha-wave-2-100L-50KP.csv",
                "alpha-wave-2-100L.csv",
                "alpha-wave-2-125L-150L-ext-0KP.csv",
                "alpha-wave-2-125L-150L-ext.csv",
                "alpha-wave-2-100L-125L-ext.csv",
                "alpha-wave-2-125L-200L-ext.csv",
                "alpha-wave-2-125L.csv",
                "alpha-wave-2-150L.csv",
                "alpha-wave-2-200L-50KP.csv",
                "alpha-wave-2.5-100L-50KP.csv",
                "alpha-wave-2.5-125L-50KP.csv",
                "alpha-wave-2.5-150L-50KP.csv",
                "alpha-wave-2.5-200L-50KP.csv",

                "alpha-wave-2.1.5-100L-50KP.csv",
                "alpha-wave-2.1.5-150L-50KP.csv",
                "alpha-wave-2.1.5-200L-50KP.csv",

                "alpha-wave-2.1.0-100L-50KP.csv",
                "alpha-wave-2.1.0-150L-50KP.csv",
                "alpha-wave-2.1.0-200L-50KP.csv",


                "alpha-wave-2.1.1-100L-50KP.csv",
                "alpha-wave-2.1.1-150L-50KP.csv",
                "alpha-wave-2.1.1-200L-50KP.csv",
                "alpha-wave-2.1.0-200L-0KP.csv"

            ]

            var mmConfigs = [
                "mm-5.csv",
                "mm-12.csv",
                "mm-12b.csv",
                "mm-12c.csv",
                "mm-5d.csv",
                "mm-5h.csv",
                "mm-5j.csv",
                "mm-5aa.csv",
                "mm-17-infinite.csv",
                "mm-18b-infinite.csv",
                "mm-18-infinite.csv",
                "mm-16b-infinite.csv",
                "mm-16a-infinite.csv",
                "mm-19-infinite.csv",
                "mm-12b-infinite.csv",
                "mm-12-hedge.csv",
                "mm-16-infinite.csv",
                "mm-16-hedge.csv",
                "mm-5-reduce-1.csv",
                "mm-16.csv",
                "mm-16-hedge-positive.csv"
            ]


            var selectBox

            if (bot.configFileName.includes("alpha-wave-1")) {
                selectBox = <select value={this.state.selectedConfig} onChange={(event) => {this.updateSelectedConfig(event)}}>
                                {a1Configs.map((config, index) => (
                                    <option key={index} value={config}>
                                        {config}
                                    </option>
                                ))}
                            </select>
            } else if (bot.configFileName.includes("alpha-wave-2")) {
                selectBox = <select value={this.state.selectedConfig} onChange={(event) => {this.updateSelectedConfig(event)}}>
                                {a2Configs.map((config, index) => (
                                    <option key={index} value={config}>
                                        {config}
                                    </option>
                                ))}
                            </select>
            } else if (bot.configFileName.includes("mm-")) {
                selectBox = <select value={this.state.selectedConfig} onChange={(event) => {this.updateSelectedConfig(event)}}>
                                {mmConfigs.map((config, index) => (
                                    <option key={index} value={config}>
                                        {config}
                                    </option>
                                ))}
                            </select>
            }

            if (this.state.loadingUpdateConfig) {
                updateConfigPanel = <div className='bot-config-panel'>

                    <div className='loading-small'><ReactLoading
                                type={"bars"}
                                color={"#03fc4e"}
                                height={100}
                                width={100}
                            /></div>
                    

                </div>
            } else {

                updateConfigPanel = 
                
                <div>
                    <div className='bot-config-panel'>

                        <div className='fund-panel-row'>
                            <div className='fund-panel-row-key'>Config</div>
                            <div className={'fund-panel-row-value ' + ""}>
                                {selectBox}
                            </div>
                        </div>

                        <div className='fund-panel-row'>
                            <div className='fund-panel-row-key'>Divider</div>
                            <div className={'fund-panel-row-value ' + ""}><input type='number' min={10} max={250} step={1}  value={this.state.selectedDivider} onChange={(event) => {this.updateSelectedDivider(event)}} /></div>
                        </div>

                        <div className='fund-panel-row'>
                            <div className='trader-function-button' onClick={() => {this.saveUpdateConfig()}}>Save</div>
                        </div>

                    </div>
                    <div className='bot-config-panel'>

                      

                        <div className='fund-panel-row'>
                            <div className='fund-panel-row-key'>Risk Reduction Level</div>
                            <div className={'fund-panel-row-value ' + ""}><input type='number' min={0} max={200} step={1}  value={this.state.riskReductionLevel} onChange={(event) => {this.updateRiskReductionLevel(event)}} /></div>
                        </div>

                        <div className='fund-panel-row'>
                            <div className='trader-function-button' onClick={() => {this.saveRiskReductionLevel()}}>Save</div>
                        </div>

                        </div>
                </div>
            }
        }

        if (this.state.showCycles) {
            if (this.state.cycles === undefined) {
                cyclesPanel = <div className='bot-config-panel'>

                    <div className='loading-small'><ReactLoading
                                type={"bars"}
                                color={"#03fc4e"}
                                height={100}
                                width={100}
                            /></div>
                </div>
            } else {
                var rows = []

                rows.push(<div className='cycle-summary' onClick={()=>{this.showTrades(bot.numberOfCycles)}}>

                <div className='cycle-summary-row'>
                    <div className='cycle-summary-row-key'>Current Cycle</div>
                    <div className={'cycle-summary-row-value ' + ""}></div>
                </div>
                <div className='cycle-summary-row'>
                    <div className='cycle-summary-row-key'>Start</div>
                    <div className={'cycle-summary-row-value ' + ""}>{new Date(bot.startTime).toLocaleString()}</div>
                </div>


                <div className='cycle-summary-row'>
                    <div className='cycle-summary-row-key'>Length</div>
                    <div className={'cycle-summary-row-value ' + ""}>{Utils.getTimeDifference(new Date(bot.startTime), new Date())}</div>
                </div>


                <div className='cycle-summary-row'>
                    <div className='cycle-summary-row-key'>Cycle ID</div>
                    <div className={'cycle-summary-row-value ' + ""}>{bot.numberOfCycles}</div>
                </div>

            </div>)
                this.state.cycles.forEach(cycle => {

                    var start = new Date(cycle.start)
                    var end = new Date(cycle.end)

                    rows.push(<div className='cycle-summary'  onClick={()=>{this.showTrades(cycle.botCycleId)}}>
                        <div className='cycle-summary-row'>
                            <div className='cycle-summary-row-key'>Start</div>
                            <div className={'cycle-summary-row-value ' + ""}>{start.toLocaleString()}</div>
                        </div>

                        <div className='cycle-summary-row'>
                            <div className='cycle-summary-row-key'>End</div>
                            <div className={'cycle-summary-row-value ' + ""}>{end.toLocaleString()}</div>
                        </div>

                        <div className='cycle-summary-row'>
                            <div className='cycle-summary-row-key'>Length</div>
                            <div className={'cycle-summary-row-value ' + ""}>{Utils.getTimeDifference(start, end)}</div>
                        </div>

                        <div className='cycle-summary-row'>
                            <div className='cycle-summary-row-key'>PNL</div>
                            <div className={'cycle-summary-row-value ' + (cycle.pnlUsd >= 0 ? 'green' : 'red')}>${cycle.pnlUsd}</div>
                        </div>

                        <div className='cycle-summary-row'>
                            <div className='cycle-summary-row-key'>Cycle ID</div>
                            <div className={'cycle-summary-row-value ' + ""}>{cycle.botCycleId}</div>
                        </div>

                        <div className='cycle-summary-row'>
                            <div className='cycle-summary-row-key'>Swept</div>
                            <div className={'cycle-summary-row-value ' + ""}>{cycle.swept ? "Yes" : "No"}</div>
                        </div>

                    </div>)
                });

                cyclesPanel = <div className='bot-config-panel'>

                    {rows}
                </div>
            }

            if (this.state.showTrades) {
                if (this.state.trades === undefined) {
                    cyclesPanel = <div className='bot-config-panel'>
    
                        <div className='loading-small'><ReactLoading
                                    type={"bars"}
                                    color={"#03fc4e"}
                                    height={100}
                                    width={100}
                                /></div>
                    </div>
                } else {

                    var trades = []

                    trades.push(
                    <div className='cycle-summary-row' onClick={()=>{this.hideTrades()}}>
                        <div className='cycle-summary-row-key'><img src="arrow-back-3783.png" /></div>
                    </div>

                    )

                    this.state.trades.forEach((trade) => {
                        trades.push(<div className='latestTrade'>
                            <div className='latestTrade-symbol'><img src={trade.symbol.toLowerCase().replace("/", "") + ".svg"} /> {trade.symbol}</div>
                            <div className={'latestTrade-side ' + (trade.side === 'SELL' ? 'red' : 'green')}>{trade.side}</div>
                            <div className='latestTrade-quantity'>{Utils.numberWithCommas(trade.quantity, 0)}</div>
                            <div className='latestTrade-price'>{trade.price}</div>
                            <div className='latestTrade-notional'>${Utils.numberWithCommas(trade.notional, 2)}</div>
                            <div className='latestTrade-fee'>Fee: <span className='red'>${trade.fee}</span></div>
                            <div className='latestTrade-date'>{new Date(trade.date).toUTCString().split(",")[1]}</div>
                            <div className='latestTrade-exchange'><img src={trade.exchange + '.png'} /> {Utils.lpName(trade.exchange)}</div>

                        </div>)
                        }
                    )

                    cyclesPanel = <div className='bot-config-panel'>

                    {trades}
                    </div>
                }
            }
        }

        return (
        <div className='bot-container'>
        <div className='bot-container-inner'>
                <div className='bot-left'>
                    <div className='bot-symbol bot-row'>
                        {bot.symbol}
                    </div>
                    <div className='bot-price bot-row'>
                        {bot.price} <img src={bot.priceLp + '.png'} /> 
                    </div>
                    <div className='bot-spread bot-row'>
                        <span className='bot-key'>Spread</span>
                        <span className='bot-value'>{Utils.numberWithCommas(bot.spread, 1)}</span>

                    </div>
                    <div className={'bot-'+bot.direction}>
                        {bot.direction}
                    </div>
                    <div className='bot-distance bot-row'>
                        <span className='bot-key'>Distance</span>
                        <span className={'bot-value ' + (bot.distance > 0 ? 'red' : 'green')}>{Utils.numberWithCommas(bot.distance, 1)}</span>

                    </div>
                    <div className='bot-config bot-row'>
                        {bot.config}
                    </div>
                    <div className='bot-row bot-state'>{bot.status !== "RUNNING" ? bot.status : ""}</div>
                    <div className='bot-row bot-state'>{bot.riskReduction ? "Risk Reducing" : ""}</div>
                    <div className='bot-config bot-row'>
                        {bot.riskReductionLevel > 0 ? "Current RRL " + bot.riskReductionLevel : ""}
                    </div>
                    <div className='bot-config bot-row'>
                        {bot.traderRiskReductionLevel > 0 ? "Default RRL " + bot.traderRiskReductionLevel : ""}
                    </div>
                    
                </div>
                <div className='bot-right'>
                    <div className='bot-position bot-row'>
                        <span className='bot-key'>Position</span>
                        <span className='bot-value'>{Utils.numberWithCommas(bot.position, 0)}</span>
                    </div>

                    <div className='bot-avgPrice bot-row'>
                        <span className='bot-key'>Avg Price</span>
                        <span className='bot-value'>{Number.parseFloat(bot.averagePrice).toFixed(bot.symbol.includes("JPY") ? 3 : 6)}</span>
                    </div>
                    <div className='bot-totalRealised bot-row'>
                        <span className='bot-key'>Total Realised</span>
                        <span className={'bot-value ' + (bot.totalRealisedPnl < 0 ? 'red' : 'green') }>
                            {Utils.numberWithCommas(bot.totalRealisedPnl, 2)}
                        </span>
                    </div>

                    <div className='bot-cycleRealised bot-row'>
                        <span className='bot-key'>Cycle Realised</span>
                        <span className={'bot-value ' + (bot.cycleRealisedPnl < 0 ? 'red' : 'green') }>
                            {Utils.numberWithCommas(bot.cycleRealisedPnl, 2)}
                        </span>
                    </div>

                    <div className='bot-totalFees bot-row'>
                        <span className='bot-key'>Total Fees</span>
                        <span className='bot-value red'>
                            {Utils.numberWithCommas(bot.totalFees, 2)}
                        </span>
                    </div>

                    <div className='bot-unrealised bot-row'>
                        <span className='bot-key'>Unrealised</span>
                        <span className={'bot-value ' + (bot.unrealisedPnl < 0 ? 'red' : 'green') }>
                            {Utils.numberWithCommas(bot.unrealisedPnl, 2)}
                        </span>
                    </div>
                    <div className='bot-cycles bot-row'>
                        <span className='bot-key'>Cycles</span>
                        <span className='bot-value'>
                            {bot.numberOfCycles}
                        </span>
                    </div>
                    
                    <div className='bot-levels  bot-row'>
                        <span className='bot-key'>Levels</span>
                        <span className={'bot-value ' + (bot.level*1.0/bot.levels*1.0 >= 0.9 ? 'red' : "")}>
                            {bot.level} / {bot.levels}
                        </span>
                    </div>
                    <div className='bot-volume  bot-row'>
                        <span className='bot-key'>Volume</span>
                        <span className='bot-value'>${Utils.numberWithCommas(bot.volume, 0)}</span>
                    </div>
                    <div className='bot-cycles bot-row'>
                        <span className='bot-key'>Cycle Start</span>
                        <span className='bot-value'>
                            {new Date(bot.startTime).toLocaleString()}
                        </span>
                    </div>
                    <div className='bot-id bot-row'>
                        {bot.id}
                    </div>

                   
                </div>

               

                      </div>
        
                      {traderFunctions}
                    {updateConfigPanel}
                    {cyclesPanel}

        </div>
                      )
    }
}

export default Bot;