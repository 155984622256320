// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pricingSpeed {
    clear: both;
    padding: 5px;
}

.pricingSpeed-lp {
    float: left;
    font-weight: bold;
    line-height: 45px;
    font-size: 18px;
}

.pricingSpeed-details {
    float: right;
}

.pricingSpeed-speed {
    text-align: right;
    padding: 2px;
    font-size: 20px;;
}

.pricingSpeed-processtime {
    text-align: right;
    font-size: 15px;;
    margin-bottom: 5px;;
}`, "",{"version":3,"sources":["webpack://./src/Fund/Prices/PricingSpeeds.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,iBAAiB;IACjB,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,iBAAiB;IACjB,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,kBAAkB;AACtB","sourcesContent":[".pricingSpeed {\n    clear: both;\n    padding: 5px;\n}\n\n.pricingSpeed-lp {\n    float: left;\n    font-weight: bold;\n    line-height: 45px;\n    font-size: 18px;\n}\n\n.pricingSpeed-details {\n    float: right;\n}\n\n.pricingSpeed-speed {\n    text-align: right;\n    padding: 2px;\n    font-size: 20px;;\n}\n\n.pricingSpeed-processtime {\n    text-align: right;\n    font-size: 15px;;\n    margin-bottom: 5px;;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
