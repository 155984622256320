import React, { Component } from 'react';
import Utils from '../../Utils'
import './GroupDetails.css'
import Bot from './Bot';

class GroupDetails extends Component {


    componentDidMount() {

    }


    componentWillUnmount() {
        
    }


    render() {

        var groups = this.props.groups


        var groupDetails = []

        groups.forEach((group) => {

            var bots = []

            group.bots.forEach((bot) => {
                bots.push(<Bot bot={bot} />);
            })

            var groupVersion1;

            if (group.groupVersion === 1) {

                var takeProfit = group.groupTakeProfit;
                if (group.groupTakeProfitPercent > 0) {
                    takeProfit = Math.min(takeProfit, group.groupNop*group.groupTakeProfitPercent)
                }

                if (takeProfit < group.groupMinimumTakeProfit) {
                    takeProfit = group.groupMinimumTakeProfit;
                }

                groupVersion1 = <div>

                    <div className='fund-panel-row'>
                        <div className='fund-panel-row-key-small'>NOP</div>
                        <div className='fund-panel-row-value-small'>${Utils.numberWithCommas(group.groupNop, 0)}</div>
                    </div>

                    <div className='fund-panel-row'>
                        <div className='fund-panel-row-key-small'>Take Profit</div>
                        <div className='fund-panel-row-value-small'>${Utils.numberWithCommas(takeProfit, 2)}</div>
                    </div>

                    <div className='fund-panel-row'>
                        <div className='fund-panel-row-key-small'>Group Float PNL</div>
                        <div className={'fund-panel-row-value-small '+(group.groupSavedPnlUsd < 0 ? 'red' : 'green')}>${Utils.numberWithCommas(group.groupSavedPnlUsd, 2)}</div>
                    </div>

                    <div className='fund-panel-row'>
                        <div className='fund-panel-row-key-small'>Cycles</div>
                        <div className='fund-panel-row-value-small'>{group.groupCycleNumber}</div>
                    </div>

                    

                </div>
            }

            var groupConcentrationLimits
            var groupPositionLadder
            var positionLadderCurrencies = []

            var groupPositionLadderRows = []
            group.positionLadder.forEach((item) => {
                positionLadderCurrencies.push(item.currency)
                groupPositionLadderRows.push(<div className='position-ladder-small'>
                    <div  className='position-ladder-small-currency'>{item.currency}</div><div  className={'position-ladder-small-amount ' + (item.amount < 0 ? 'red' : 'green') }>{Utils.numberWithCommas(item.amount, 0)}</div><div  className={'position-ladder-small-amountUsd ' + (item.amount < 0 ? 'red' : 'green') }>{Utils.numberWithCommas(item.amountUsd, 0)} USD</div></div>)
            })


            groupPositionLadder = <div className='fund-panel-row'>
                    <div className='fund-panel-row-key-small'>Group Ladder</div>
                    <div className={'fund-panel-row-value-small '}>{groupPositionLadderRows}</div>
                </div>
            if (group.concentrationLimits !== undefined) {
                var concentrationRows = []
                positionLadderCurrencies.forEach((currency) => {
                    if (group.concentrationLimits[currency] !== undefined) {
                        if (group.concentrationLimits[currency] !== 0) {
                            concentrationRows.push(<div className='group-concentrations'><div className='group-concentrations-currency'>{currency}</div><div  className='group-concentrations-amount'>{Utils.numberWithCommas(group.concentrationLimits[currency], 0)}</div></div>)
                        }
                    }
                })
               

                    groupConcentrationLimits =  <div className='fund-panel-row'>
                    <div className='fund-panel-row-key-small'>Concentration Limits</div>
                    <div className={'fund-panel-row-value-small '}>{concentrationRows}</div>
                </div>
            }


            groupDetails.push(<div className='fund-panel' id={group.id}>
            <div className='fund-panel-title'>
                {group.name}
            </div>
            <div className='fund-panel-body'>
                        <div className='fund-panel-row'>
                            <div className='fund-panel-row-key-small'>Realised PNL</div>
                            <div className='fund-panel-row-value-small green'>${Utils.numberWithCommas(group.realisedPnl, 2)}</div>
                        </div>

                        <div className='fund-panel-row'>
                            <div className='fund-panel-row-key-small'>Pending PNL</div>
                            <div className='fund-panel-row-value-small green'>${Utils.numberWithCommas(group.pendingPnl, 2)}</div>
                        </div>

                        <div className='fund-panel-row'>
                            <div className='fund-panel-row-key-small'>Unrealised PNL</div>
                            <div className={'fund-panel-row-value-small '+(group.unrealisedPnl < 0 ? 'red' : 'green')}>{group.unrealisedPnl < 0 ? '-' : ''}${Utils.numberWithCommas(Math.abs(group.unrealisedPnl), 2)}</div>
                        </div>

                        <div className='fund-panel-row'>
                            <div className='fund-panel-row-key-small'>Fees</div>
                            <div className='fund-panel-row-value-small red'>${Utils.numberWithCommas(group.fees, 2)}</div>
                        </div>

                        <div className='fund-panel-row'>
                            <div className='fund-panel-row-key-small'>Net PNL</div>
                            <div className={'fund-panel-row-value-small '+(group.netPnl < 0 ? 'red' : 'green')}>{group.netPnl < 0 ? '-' : ''}${Utils.numberWithCommas(Math.abs(group.netPnl), 2)}</div>
                        </div>



                        <div className='fund-panel-row'>
                            <div className='fund-panel-row-key-small'>Total Volume</div>
                            <div className='fund-panel-row-value-small'>${Utils.numberWithCommas(group.volume, 0)}</div>
                        </div>

                        <div className='fund-panel-row'>
                            <div className='fund-panel-row-key-small'>External Volume</div>
                            <div className='fund-panel-row-value-small'>${Utils.numberWithCommas(group.externalVolume, 0)}</div>
                        </div>

                        {groupVersion1}

                        <div className='fund-panel-row'>
                            <div className='fund-panel-row-key-small'>Profit/Yard</div>
                            <div className='fund-panel-row-value-small green'>${Utils.numberWithCommas(group.profitPerYard, 0)}</div>
                        </div>

                        {groupPositionLadder}
                        {groupConcentrationLimits}

                        <div className='panel-divider'></div>

                        <div className='groupDetails-bots'>{bots}</div>

                        <div className='panel-divider'></div>
                        <div className='groupDetails-id'>{group.id}</div>
            </div>
        </div>)
            }
        )

        return (<div className='groupDetails-container'>
          
                {groupDetails}
                      </div>)
    }
}

export default GroupDetails;