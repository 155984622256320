// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.groupsSummary-container {

}

.groupSummary {
    width: 100%;
    display: flex;
    margin-top: 20px;;
}

.groupSummary-left {
    cursor: pointer;
}

.groupSummary-left:hover {
    -webkit-text-decoration: underline dotted;
            text-decoration: underline dotted;
}

.groupSummary-right {
    flex: 1 1;
    text-align: right;
}

.groupSummary-symbol {
    font-size: 20px;
    font-weight: bold;
    text-align: left;
    padding: 3px;
    line-height: 80px;
}

.groupSummary-symbol img {
    width: 23px;
    padding-right: 2px;
}

.groupSummary-unrealised {
    font-size: 18px;
    margin-top: 4px;;

}

.groupSummary-realised {
    font-size: 18px;

}

.groupSummary-net {
    font-size: 23px;
    border-top: 1px dotted #fff;
}`, "",{"version":3,"sources":["webpack://./src/Fund/Home/GroupsSummary.css"],"names":[],"mappings":"AAAA;;AAEA;;AAEA;IACI,WAAW;IACX,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,yCAAiC;YAAjC,iCAAiC;AACrC;;AAEA;IACI,SAAO;IACP,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,gBAAgB;IAChB,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,eAAe;;AAEnB;;AAEA;IACI,eAAe;;AAEnB;;AAEA;IACI,eAAe;IACf,2BAA2B;AAC/B","sourcesContent":[".groupsSummary-container {\n\n}\n\n.groupSummary {\n    width: 100%;\n    display: flex;\n    margin-top: 20px;;\n}\n\n.groupSummary-left {\n    cursor: pointer;\n}\n\n.groupSummary-left:hover {\n    text-decoration: underline dotted;\n}\n\n.groupSummary-right {\n    flex: 1;\n    text-align: right;\n}\n\n.groupSummary-symbol {\n    font-size: 20px;\n    font-weight: bold;\n    text-align: left;\n    padding: 3px;\n    line-height: 80px;\n}\n\n.groupSummary-symbol img {\n    width: 23px;\n    padding-right: 2px;\n}\n\n.groupSummary-unrealised {\n    font-size: 18px;\n    margin-top: 4px;;\n\n}\n\n.groupSummary-realised {\n    font-size: 18px;\n\n}\n\n.groupSummary-net {\n    font-size: 23px;\n    border-top: 1px dotted #fff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
