import React, { Component } from 'react';
import Utils from '../../Utils'

class UnsweptProfits extends Component {
    constructor(props) {
        super(props);

        this.state = {
            details: props.details
        }
    }

    componentDidMount() {

    }


    componentWillUnmount() {
        
    }


    render() {
        var positionLadder = this.props.unsweptProfits

        let positions = []

        positionLadder.sort(
            function(a,b) { 
                if (a === undefined || b === undefined) { 
                    return 0 
                }
                return a.currency.localeCompare(b.currency)
            }
                ).forEach((pos) => {
                    var position = pos

                    positions.push(
                        <div className='position'>
                            <div className='position-currency'>
                            {position.currency}
                            </div>

                            <div className='position-details'>
                                <div className='position-details-amount'>
                                    {Utils.numberWithCommas(position.amount, 0)}
                                </div>
                                <div className={'position-details-amountusd ' + (position.amountUsd < 0 ? 'red' : 'green')}>
                                    {Utils.numberWithCommas(position.amountUsd, 0)} USD
                                </div>
                            </div>
                        </div>
                        )
                    });

        return (<div className='positionLadder'>
            {positions}
                      </div>)

    }
}

export default UnsweptProfits;