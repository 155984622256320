// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-container {
    display: flex;
    flex-direction: row;
    padding: 10px;
    height: 50px;
}

.header-left {
    flex-grow: 1;
    text-align: left;
}


.header-logo {
    width: 200px;
    display: block;
    margin-top: 10px;
}
   
.header-logo img {
   width: 200px;
}
   

.header-right {
    flex-shrink: 1;
    text-align: right;
    display: flex;
    column-gap: 5px;
}


.header-buton {
    flex-basis: 1;
    margin-top: 10px;
    padding: 10px;
    width: 60px;
    height: 18px;
    cursor: pointer;
    color: #fff;
    border-radius: 5px;
    text-align: center;
    border: 1px solid #fff;

}

  .header-buton:hover {
    background-color: #fff;
    color: #000;
    border: 1px solid #fff;
  }

  .header-buton-dark {
    flex-basis: 1;
    padding: 3px;
    width: 60px;
    height: 24px;
    cursor: pointer;
    color: #fff;
    border-radius: 5px;
    text-align: center;
    border: 1px solid #000;
    background-color: #000;
    font-size: 13px;
    line-height: 24px;
  }

  .header-buton-dark:hover {
    background-color: #fff;
    color: #000;
  }`, "",{"version":3,"sources":["webpack://./src/Dashboard/Header.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,gBAAgB;AACpB;;;AAGA;IACI,YAAY;IACZ,cAAc;IACd,gBAAgB;AACpB;;AAEA;GACG,YAAY;AACf;;;AAGA;IACI,cAAc;IACd,iBAAiB;IACjB,aAAa;IACb,eAAe;AACnB;;;AAGA;IACI,aAAa;IACb,gBAAgB;IAChB,aAAa;IACb,WAAW;IACX,YAAY;IACZ,eAAe;IACf,WAAW;IACX,kBAAkB;IAClB,kBAAkB;IAClB,sBAAsB;;AAE1B;;EAEE;IACE,sBAAsB;IACtB,WAAW;IACX,sBAAsB;EACxB;;EAEA;IACE,aAAa;IACb,YAAY;IACZ,WAAW;IACX,YAAY;IACZ,eAAe;IACf,WAAW;IACX,kBAAkB;IAClB,kBAAkB;IAClB,sBAAsB;IACtB,sBAAsB;IACtB,eAAe;IACf,iBAAiB;EACnB;;EAEA;IACE,sBAAsB;IACtB,WAAW;EACb","sourcesContent":[".header-container {\n    display: flex;\n    flex-direction: row;\n    padding: 10px;\n    height: 50px;\n}\n\n.header-left {\n    flex-grow: 1;\n    text-align: left;\n}\n\n\n.header-logo {\n    width: 200px;\n    display: block;\n    margin-top: 10px;\n}\n   \n.header-logo img {\n   width: 200px;\n}\n   \n\n.header-right {\n    flex-shrink: 1;\n    text-align: right;\n    display: flex;\n    column-gap: 5px;\n}\n\n\n.header-buton {\n    flex-basis: 1;\n    margin-top: 10px;\n    padding: 10px;\n    width: 60px;\n    height: 18px;\n    cursor: pointer;\n    color: #fff;\n    border-radius: 5px;\n    text-align: center;\n    border: 1px solid #fff;\n\n}\n\n  .header-buton:hover {\n    background-color: #fff;\n    color: #000;\n    border: 1px solid #fff;\n  }\n\n  .header-buton-dark {\n    flex-basis: 1;\n    padding: 3px;\n    width: 60px;\n    height: 24px;\n    cursor: pointer;\n    color: #fff;\n    border-radius: 5px;\n    text-align: center;\n    border: 1px solid #000;\n    background-color: #000;\n    font-size: 13px;\n    line-height: 24px;\n  }\n\n  .header-buton-dark:hover {\n    background-color: #fff;\n    color: #000;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
